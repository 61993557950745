import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import SkinSelector from '../../lib/components/settings/SkinSelector';
import { Page } from '../../../declarations/models/Page';
import { Skin } from '../../../declarations/models/Skin';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { LinkListBlock } from '../../../declarations/models/blocks/LinkListBlock';
import ActionMenu from '../../../components/ActionMenu';
import { useEditorData } from '../../lib/components/EditorDataProvider';
import { LinkStyle } from '../../../declarations/models/LinkStyle';

const VIEW_OPTIONS: Array<'list' | 'teasers'> = ['list', 'teasers'];
const VIEW_ICONS: Record<string, ReactNode> = {
  list: <MaterialSymbol name='view_headline' fill />,
  teasers: <MaterialSymbol name='view_column' fill sharp sx={{ transform: 'rotate(90deg) scaleY(1.3)' }} />,
};

export const PageBlockLinkListViewSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath, block } = useCurrentBlock();
  const { skins } = useEditorData();
  const currentBlock = block as LinkListBlock;
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<LinkListBlock>, `${typeof blockPath}.view`>({
    name: `${blockPath}.view`,
  });

  const availableSkins = useMemo<Array<Skin>>(() => {
    return skins?.filter((skin) => skin.scope?.includes('grid') !== false);
  }, [skins]);

  const {
    field: { value: skinClass, onChange: setSkinClass },
  } = useController<Page, `${typeof blockPath}.skin`>({ name: `${blockPath}.skin` });

  const selectedSkin = useMemo<Skin | null>(() => {
    return availableSkins.find((skin) => skin.class === skinClass) || null;
  }, [availableSkins, skinClass]);

  const handleSkinChanged = (skin: Skin | null) => {
    setSkinClass(skin?.class || '');
  };

  return (
    <>
      <ActionMenu
        id='link-list-view-selector'
        ariaLabel={tAria('components.PageBlockLinkListViewSetting.ActionMenuLabel')}
        tooltip={tComponents('PageBlockLinkListViewSetting.Tooltip')}
        tooltipPlacement='top'
        icon={value ? VIEW_ICONS[value] : VIEW_ICONS.list}>
        {VIEW_OPTIONS.map((viewOption) => (
          <MenuItem key={viewOption} onClick={() => onChange(viewOption)} selected={value === viewOption}>
            <ListItemIcon>{VIEW_ICONS[viewOption]}</ListItemIcon>
            <ListItemText>{tComponents(`PageBlockLinkListViewSetting.options.${viewOption}`)}</ListItemText>
          </MenuItem>
        ))}
      </ActionMenu>

      <SkinSelector
        availableSkins={availableSkins}
        selectedSkin={selectedSkin}
        onSkinChange={handleSkinChanged}
        disabled={currentBlock.linkstyle !== LinkStyle.ARROW_LINKS}
      />
    </>
  );
};

export default PageBlockLinkListViewSetting;
