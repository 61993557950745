import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { SkinSelectGroup } from '@/components/SkinSelectGroup';
import { Skin } from '@/declarations/models/Skin';
import { useStore } from '../../../components/store/Store';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import Container from '../../../components/Container';
import SwitchInput from '../../../components/forms/SwitchInput';
import CheckboxInput from '../../../components/forms/CheckboxInput';
import TextInput from '../../../components/forms/TextInput';
import { BlockSpecificPage } from '../../../editor/lib/declarations/BlockSpecificPage';
import { HeaderMenuBlock } from '../../../declarations/models/blocks/settingsBlocks/HeaderMenuBlock';

export interface LanguageMenuInfoLineSettingsProps {
  blockPath: BlockPath;
}

export const LanguageMenuIfoLineSettings: FC<LanguageMenuInfoLineSettingsProps> = ({ blockPath }) => {
  const { t } = useTranslation('components');
  const { state } = useStore();
  const isSuperAdmin = state.currentUser?.user?.is_super_admin;
  const {
    field: { value: skinValue, onChange: setSkin },
  } = useController<BlockSpecificPage<HeaderMenuBlock>, `${BlockPath}.infoLineSkin`>({
    name: `${blockPath}.infoLineSkin`,
  });

  const onSkinChange = (skin: Skin | null) => {
    let _skin = skin?.class;
    if (_skin === skinValue) {
      _skin = '';
    }
    setSkin(_skin);
  };

  return (
    <Container fullWidth top left>
      <Container column fullWidth top left gap={3}>
        <Container fullWidth top left>
          <SwitchInput
            path={`${blockPath}.includeInfoLine`}
            label={t('Settings.LanguageSettings.LanguageMenuSettings.includeInfoLine')}
            defaultValue={false}
          />
        </Container>
        <Container fullWidth top left>
          <CheckboxInput
            path={`${blockPath}.infoLineFrontPageOnly`}
            label={t('Settings.LanguageSettings.LanguageMenuSettings.infoLineFrontPageOnly')}
          />
        </Container>
        <TextInput
          path={`${blockPath}.infoLineText`}
          label={t('Settings.LanguageSettings.LanguageMenuSettings.infoLineText')}
          defaultValue=''
        />
        <TextInput
          path={`${blockPath}.infoLineButtonText`}
          label={t('Settings.LanguageSettings.LanguageMenuSettings.infoLineButtonText')}
          defaultValue=''
        />

        <CheckboxInput
          path={`${blockPath}.infoLineShowAsLink`}
          label={t('Settings.LanguageSettings.LanguageMenuSettings.infoLineShowAsLink')}
        />

        <TextInput
          path={`${blockPath}.infoLineLink`}
          label={t('Settings.LanguageSettings.LanguageMenuSettings.infoLineLink')}
          defaultValue=''
        />

        <SkinSelectGroup
          label={t('EventData.SkinLabel')}
          availableSkins={state?.selectedSiteSkins ?? []}
          onSkinChange={(skin) => onSkinChange(skin)}
          selectedSkin={(state?.selectedSiteSkins ?? []).find((s) => s.class === skinValue)}
        />
      </Container>
    </Container>
  );
};

export default LanguageMenuIfoLineSettings;
