import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import WebpageTrackingSettings from '@/views/SettingsView/WebpageSettingsView/WebpageTrackingSettings';
import WebpageEKulturIntegrationsSettings from '@/views/SettingsView/WebpageSettingsView/WebpageEKulturIntegrationsSettings';
import WebpageIconsSettings from '@/views/SettingsView/WebpageSettingsView/WebpageIconsSettings';
import SaveFormButton from '@/components/SaveFormButton';
import Container from '@/components/Container';
import { Typography } from '@mui/material';
import { ChildrenProp } from '../../../declarations/ChildrenProp';
import BaseSettingsViewForm from '../../BaseSettingsViewForm';
import { Api } from '../../../services/Api';
import { useStore } from '../../../components/store/Store';
import { Site } from '../../../declarations/models/Site';

export const WebpageSettingsView: FC<ChildrenProp> = () => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { state, changeSiteContext } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;

  const handleSaveSettingsForm = useCallback(
    async (site: Site): Promise<void | Site> => {
      const savedFormData = await Api.saveSiteSettings<Site>(site).fetchDirect(null);
      if (!savedFormData) {
        enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
      } else {
        // const updatedSite = await Api.getSite(selectedSiteId).fetchDirect(null);
        // console.log('savedFormData', savedFormData);
        changeSiteContext(savedFormData);

        enqueueSnackbar(tCommon('saved'), { variant: 'success' });
        return savedFormData;
      }
    },
    [enqueueSnackbar, tCommon, changeSiteContext],
  );

  const handleLoadModel = () => {
    return Api.getSite(selectedSiteId);
  };

  const otherLanguageCodes = state.selectedSite?.site_contents
    ?.filter((content) => content.locale !== state.selectedSite?.default_language)
    .map((content) => content.locale);

  return (
    <BaseSettingsViewForm<Site>
      title=''
      onSubmit={handleSaveSettingsForm}
      loadModel={handleLoadModel}
      withLayout={false}>
      <Container left fullWidth p={2}>
        <Typography mt={2} variant='h1' fontSize='xx-large'>
          {tComponents(`Settings.WebpageSettings.General`)}
        </Typography>
      </Container>
      <Container left fullWidth column p={2} sx={{ overflow: 'auto' }}>
        <Typography mt={0} mb={1}>
          {tComponents(`Settings.WebpageSettings.SiteID`)}: <strong>{selectedSiteId}</strong>
          <br />
          {tComponents(`Settings.WebpageSettings.SiteName`)}: <strong>{state.selectedSite?.name}</strong>
          <br />
          {tComponents(`Settings.WebpageSettings.SiteDefaultLanguage`)}:{' '}
          <strong>{tCommon(`Languages.${state.selectedSite?.default_language}`)} </strong> + (
          {otherLanguageCodes?.length}):
          {otherLanguageCodes?.map((code) => tCommon(`Languages.${code}`)).join(', ')}
          <br />
          {tComponents(`Settings.WebpageSettings.SiteUrl`)}: <strong>{state.selectedSite?.url}</strong>
        </Typography>
        <Typography variant='h2' fontSize='x-large' mt={3} mb={1}>
          {tComponents(`Settings.WebpageSettings.WebpageTrackingSettings.title`)}
        </Typography>
        <WebpageTrackingSettings />
        <Typography variant='h2' fontSize='x-large' mt={5} mb={1}>
          {tComponents(`Settings.WebpageSettings.WebpageEKulturIntegrationsSettings.title`)}
        </Typography>
        <WebpageEKulturIntegrationsSettings />
        <Typography variant='h2' fontSize='x-large' mt={5} mb={1}>
          {tComponents(`Settings.WebpageSettings.WebpageIconsSettings.title`)}
        </Typography>
        <WebpageIconsSettings />
        <Container fullWidth right>
          <SaveFormButton />
        </Container>
      </Container>
    </BaseSettingsViewForm>
  );
};

export default WebpageSettingsView;
