import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Container from '@/components/Container';
import { SettingsMenu } from '@/components/SettingsMenu';
import Styles from '@/assets/js/Styles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const SettingsView: FC = () => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const menuItems = [
    { path: '/settings/webpage', label: tComponents('MainMenu.settings.webpage') },
    { path: '/settings/styles', label: tComponents('MainMenu.styles') },
    { path: '/settings/owner', label: tComponents('MainMenu.settings.owner') },
    { path: '/settings/sharing', label: tComponents('MainMenu.settings.sharing') },
    { path: '/settings/locations', label: tComponents('MainMenu.settings.locations') },
    { path: '/settings/users', label: tComponents('MainMenu.settings.users') },
    { path: '/settings/keywords', label: tComponents('MainMenu.settings.keywords') },
    { path: '/settings/categories', label: tComponents('MainMenu.settings.categories') },
    { path: '/settings/card-categories', label: tComponents('MainMenu.settings.cardCategories') },
    { path: '/settings/page-templates', label: tComponents('MainMenu.settings.pageTemplates') },
  ];
  return (
    <Container
      px={2}
      gap={1}
      fullWidth
      fullHeight
      top
      left
      sx={{
        backgroundColor: Styles.Colors.LIGHTEST_GREY,
      }}>
      <Container column top left fullHeight pt={2}>
        <Typography variant='h2' fontSize='xx-large'>
          {tCommon('settings')}
        </Typography>
        <SettingsMenu width='220px' menuItems={menuItems} />
      </Container>
      <Container
        fullWidth
        fullHeight
        left
        top
        sx={{
          overflow: 'auto',
        }}>
        <Outlet />
      </Container>
    </Container>
  );
};
