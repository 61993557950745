import React, { FC, useEffect, useMemo, useState } from 'react';
import { Site } from '@/declarations/models/Site';
import { useTranslation } from 'react-i18next';
import { useApi } from '@/hooks/useApi';
import { Api } from '@/services/Api';
import { SharingContentType, SiteSharingModel } from '@/declarations/models/SiteSharingModel';
import { notNil } from '@/utils/object';
import Styles from '@/assets/js/Styles';
import SearchSelector from '../SearchSelector';

export interface SharedSiteSelectorProps {
  siteContext: Site | null;
  selectedSite?: Site | null;
  setSelectedSite: (site: Site | null) => void;
  contentType: SharingContentType;
  disabled?: boolean;
}

export const SharedSiteSelector: FC<SharedSiteSelectorProps> = ({
  siteContext,
  selectedSite,
  setSelectedSite,
  contentType,
  disabled = false,
}) => {
  const { t: tCommon } = useTranslation('common');
  // Why get alle sites? The site_ids and names is availiable in det sharingData.granted list anyway:
  const [sites] = useApi(() => Api.getAllSitesForSiteSharing(), [] as Site[]);
  const [sharingData, setSharingData] = useState<SiteSharingModel | null>(null);

  useEffect(() => {
    if (!siteContext?.id) return;
    let unmounted = false;
    const ctx = Api.getSiteSharing(siteContext.id, {
      approved: true,
    });
    ctx.fetchDirect(null).then((data) => {
      if (!unmounted) {
        setSharingData(data);
      }
    });

    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [siteContext]);

  const options = useMemo(() => {
    const res = [siteContext];
    if (sharingData) {
      const sharedSites = sharingData.granted
        .filter((s) => s.content_type === contentType)
        .map((s) => {
          const foundSite = sites.find((site) => site.id === s.has_access_to_site_id);
          return foundSite;
        })
        .filter(notNil);
      res.push(...sharedSites);
    }
    return res.filter(notNil);
  }, [contentType, sharingData, siteContext, sites]);

  return (
    <>
      <SearchSelector
        id='site-selector'
        color='secondary'
        disabled={options.length < 2 || disabled}
        label={tCommon('SiteSelector.selectOrChangeActiveSite')}
        value={selectedSite ?? siteContext}
        onChange={setSelectedSite}
        options={options}
        getOptionKey={(site) => site?.id || 'null'}
        getOptionLabel={(site) => site?.name || 'null'}
        required
        searchable={sites.length > 5}
        sx={{
          fontSize: 'inherit',
          fontWeight: 'inherit',
          backgroundColor: 'white',
          border: `1px solid ${Styles.Colors.MEDIUM_GREY}`,
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
      />
    </>
  );
};
