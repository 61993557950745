import React from 'react';
import i18next from 'i18next';
import { BlockPreviewComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { KPOwnerBlockModel } from '../../../../declarations/models/blocks/KPBlock';
import KPBlockPreviewItem from './KPBlockPreviewItem';
import KPBlockOwnerPreviewItem from './KPBlockOwnerPreviewItem';

export const KPBlockOwnerPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const kpBlock = block as KPOwnerBlockModel;
  const currentLang = i18next.language;

  return (
    <div
      className={`ekultur ekultur--kulturpunkt ekultur--kpOwner ekultur--grid ekultur--${kpBlock?.width || 'auto'} ${
        kpBlock?.whiteBackground && 'skin-kulturpunkt-white'
      }`}>
      <div className='ekultur__intro'>
        {kpBlock?.visibletitle && kpBlock?.title ? <h2>{kpBlock.title}</h2> : null}
        {kpBlock?.body && <p>{kpBlock?.body}</p>}
      </div>
      <div className='ekultur__grid'>
        {kpBlock?.owners.map((o) => (
          <KPBlockOwnerPreviewItem
            key={o.id}
            lng={currentLang}
            layout={kpBlock.layout || 'img'}
            size={o.size}
            item={o}
            displayDescription={kpBlock?.displayDescription}
          />
        ))}
        {kpBlock?.presentations.map((o) => (
          <KPBlockPreviewItem
            key={o.id}
            lng={currentLang}
            id={o.id}
            size={o.size}
            type='Presentation'
            layout={kpBlock.layout || 'img'}
            displayDescription={kpBlock?.displayDescription}
          />
        ))}
      </div>
    </div>
  );
};

export default KPBlockOwnerPreview;
