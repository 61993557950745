import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Status } from '@/declarations/models/Status';
import { includeInPageIncludingFolder, includePage } from '@/utils/functions';
import { FolderView } from '@/views/ContentView/PageTree/FolderView';
import { PageFolder } from '@/declarations/models/Folder';
import { notNil } from '@/utils/object';
import { useSearchParams } from 'react-router-dom';
import { findBreadCrumb, findFolder } from '@/utils/folders';
import { Api } from '@/services/Api';
import { Button, Dialog, Typography } from '@mui/material';
import { PageFolderAutocomplete } from '@/views/ContentView/PageTree/PageFolderAutocomplete';
import { Page } from '@/declarations/models/Page';
import { useStore } from '@/components/store/Store';
import PageTreeContent from './PageTreeContent';
import { ContentViewContentContext, useContentView } from '../ContentViewContext';
import Container from '../../../components/Container';
import Styles from '../../../assets/js/Styles';

const PageTree: FC = () => {
  const { t } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { state } = useStore();
  const {
    selectionState,
    filterValues,
    hasFilterApplied,
    sortFn,
    pages,
    contentContext,
    siteFolders,
    activePageFolder,
    setActivePageFolder,
    reloadSiteFolders,
    reloadPages,
  } = useContentView();
  const { getRootNodes, getChildren, hasChildren, itemCount } = selectionState;
  const isEventView = contentContext === ContentViewContentContext.EVENT;

  const visibleNodeIds = useMemo<null | Array<string | number>>(() => {
    if (!hasFilterApplied) {
      return null;
    }
    const getVisibleNodeIds = (page: Page): Array<string | number> => {
      const key = page.id || 0;
      if (hasChildren(key)) {
        const visibleChildren = getChildren(key).reduce((ids, child) => {
          ids.push(...getVisibleNodeIds(child).filter((id) => !ids.includes(id)));
          return ids;
        }, [] as Array<string | number>);

        if (visibleChildren.length > 0) {
          visibleChildren.push(key);
          return visibleChildren;
        }
      }
      return includePage(filterValues, page) ? [key] : [];
    };

    return getRootNodes().reduce((ids, root) => {
      ids.push(...getVisibleNodeIds(root));
      return ids;
    }, [] as Array<number | string>);
  }, [filterValues, hasFilterApplied, getRootNodes, getChildren, hasChildren]);

  const [userExpandedIds, setUserExpandedIds] = React.useState<string[]>([]);
  const [searchExpandedIds, setSearchExpandedIds] = React.useState<string[]>([]);

  const handleNodeToggle = (_: React.SyntheticEvent, newExpanded: string[]) => {
    setUserExpandedIds((prev) =>
      prev.length === newExpanded.length && prev.every((id) => newExpanded.includes(id))
        ? prev // Prevent unnecessary updates
        : newExpanded,
    );
  };

  // Effect to handle search-based expansion
  useEffect(() => {
    setSearchExpandedIds((prev) => {
      if (hasFilterApplied && !!visibleNodeIds?.length) {
        const newIds = visibleNodeIds.map(String);
        return prev.length === newIds.length && prev.every((id) => newIds.includes(id))
          ? prev // Prevent unnecessary re-renders
          : newIds;
      }
      return prev.length ? [] : prev; // Avoid unnecessary state updates
    });
  }, [hasFilterApplied, visibleNodeIds]);

  // Compute the final expanded nodes
  const expandedNodes = useMemo(() => {
    return hasFilterApplied ? [...new Set([...userExpandedIds, ...searchExpandedIds])] : userExpandedIds;
  }, [hasFilterApplied, userExpandedIds, searchExpandedIds]);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.has('folder')) {
      const folderId = parseInt(searchParams.get('folder') as string, 10);
      const folder = findFolder(siteFolders, folderId);
      if (folder) {
        setActivePageFolder(folder);
      } else {
        setActivePageFolder(null);
      }
    } else {
      setActivePageFolder(null);
    }
  }, [siteFolders, searchParams, setActivePageFolder]);

  const [folderBreadcrumbs, setFolderBreadcrumbs] = React.useState<PageFolder[]>([]);
  useEffect(() => {
    if (activePageFolder) {
      const breadCrumbs = findBreadCrumb(siteFolders, activePageFolder);
      setFolderBreadcrumbs(breadCrumbs);
    } else {
      setFolderBreadcrumbs([]);
    }
  }, [activePageFolder, siteFolders]);

  const createNewFolder = async (title: string) => {
    if (!state.selectedSite?.id) {
      console.error('Cannot create folder: no selected site');
      return;
    }
    await Api.createFolder(state.selectedSite.id, { title, parent_id: activePageFolder?.id }).fetchDirect(null);
    reloadSiteFolders();
  };

  const deleteFolder = async (folderId: number) => {
    if (!state.selectedSite?.id) {
      console.error('Cannot create folder: no selected site');
      return;
    }
    await Api.deleteFolder(state.selectedSite.id, folderId).fetchDirect(null);
    reloadSiteFolders();
    // pages might have moved out of the deleted folder, so go grab them again
    reloadPages();
  };

  const [foldersToMove, setFoldersToMove] = React.useState<PageFolder[]>([]);
  const [showMoveFolderDialog, setShowMoveFolderDialog] = React.useState(false);
  const [moveTargetFolder, setMoveTargetFolder] = React.useState<PageFolder | null>(null);
  const confirmMoveFolders = async () => {
    const siteId = state.selectedSite?.id;
    if (!siteId) {
      console.error('Cannot move folders: no selected site');
      return;
    }
    const requests = foldersToMove.map((folder) => {
      return Api.updateFolder(siteId, folder.id, {
        ...folder,
        parent_id: moveTargetFolder?.id,
      }).fetchDirect(null);
    });
    await Promise.all(requests);
    reloadSiteFolders();
    reloadPages();
    setShowMoveFolderDialog(false);
  };

  const initMoveFolders = (folders: PageFolder[]) => {
    setFoldersToMove(folders);
    setShowMoveFolderDialog(true);
  };

  const updateFolder = async (folder: PageFolder) => {
    const siteId = state.selectedSite?.id;
    if (!siteId) {
      console.error('Cannot move folders: no selected site');
      return;
    }
    await Api.updateFolder(siteId, folder.id, folder).fetchDirect(null);
    reloadSiteFolders();
  };

  const navigateToFolder = (folder: PageFolder | null) => {
    if (!folder) {
      searchParams.delete('folder');
      setSearchParams(searchParams);
      return;
    }
    setSearchParams({ folder: String(folder.id) });
  };

  const renderNode = useCallback(
    (page: Page) => {
      const pageId = page.id || 0;
      const childNodes = getChildren(pageId).sort(sortFn);
      if (visibleNodeIds && !visibleNodeIds.includes(pageId)) {
        return null;
      }
      return (
        <TreeItem
          key={pageId}
          nodeId={String(pageId)}
          label={<PageTreeContent page={page} diffuse={hasFilterApplied && !includePage(filterValues, page)} />}>
          {hasChildren(pageId) ? childNodes.map(renderNode) : null}
        </TreeItem>
      );
    },
    [getChildren, hasChildren, hasFilterApplied, visibleNodeIds, filterValues, sortFn],
  );

  if (!itemCount) {
    return null;
  }

  const frontpage = pages?.find((page) => page.id === state.selectedSiteDefaultPageId);
  const pagesToDisplay = pages?.map((page) => {
    if (includeInPageIncludingFolder(filterValues, page, activePageFolder)) {
      return (
        <Container
          fullWidth
          key={page.id}
          pl={isEventView ? 0 : 2}
          sx={{
            // borderBottom: `1px solid #ccc`,
            borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
            backgroundColor:
              isEventView && page.status === Status.PUBLISHED
                ? Styles.Colors.STRONG_GREEN_TRANSPARENT
                : Styles.Colors.LIGHT_GREY,
            '&:hover': {
              backgroundColor:
                isEventView && page.status === Status.PUBLISHED
                  ? Styles.Colors.MEDIUM_GREEN_TRANSPARENT
                  : Styles.Colors.LIGHTEST_GREY,
            },
          }}>
          <PageTreeContent page={page} displayBreadcrumbs={!isEventView} />
        </Container>
      );
    }
    return null;
  });

  return (
    <>
      {hasFilterApplied || isEventView ? (
        <>
          {isEventView && state.selectedSite?.id != null && (
            <FolderView
              sx={{
                mb: 1,
              }}
              siteFolders={siteFolders}
              activePageFolder={activePageFolder}
              breadcrumbs={folderBreadcrumbs}
              onFolderClick={navigateToFolder}
              onCreateNewClick={createNewFolder}
              onFolderDelete={deleteFolder}
              onFolderEdit={updateFolder}
              onFolderMove={initMoveFolders}
            />
          )}
          {pagesToDisplay}
          {Boolean(pagesToDisplay?.filter(notNil).length === 0) && (
            <Container fullWidth pl={2}>
              {tComponents('PageTree.NoPagesFound')}
            </Container>
          )}
        </>
      ) : (
        <>
          {frontpage && (
            <Container
              fullWidth
              px={1}
              sx={{
                backgroundColor: Styles.Colors.LIGHTEST_BLUE,
                '&:hover': {
                  backgroundColor: Styles.Colors.LIGHT_BLUE,
                },
                pl: 3,
              }}>
              <PageTreeContent page={frontpage} hideBorder displayBreadcrumbs={false} />
            </Container>
          )}
          <TreeView
            sx={{ width: '100%' }}
            id='page-tree'
            aria-label={t('components.PageTree.PageTreeDescription', {
              siteName: state.selectedSite?.name || 'unknown',
            })}
            expanded={expandedNodes} // Uses computed expandedNodes
            onNodeToggle={handleNodeToggle}
            defaultCollapseIcon={<MaterialSymbol name='expand_more' />}
            defaultExpandIcon={<MaterialSymbol name='chevron_right' />}
            disableSelection>
            {getRootNodes(state.selectedSiteDefaultPageId ?? undefined)
              .filter((page) => page.id !== state.selectedSiteDefaultPageId)
              .sort(sortFn)
              .map(renderNode)}
          </TreeView>
        </>
      )}
      <Dialog
        open={showMoveFolderDialog}
        onClose={() => {
          setShowMoveFolderDialog(false);
          setMoveTargetFolder(null);
        }}>
        <Container
          fullWidth
          column
          sx={{
            p: 2,
            gap: 2,
            minWidth: '400px',
          }}>
          <Typography variant='h4'>{tComponents('FolderView.MoveDialogTitle')}</Typography>
          <PageFolderAutocomplete
            folders={siteFolders.filter((folder) => foldersToMove.every((f) => f.id !== folder.id))}
            onChange={(targetFolder) => {
              if (targetFolder?.id) {
                setMoveTargetFolder(targetFolder);
              } else {
                setMoveTargetFolder(null);
              }
            }}
          />
          <Container>
            <Button onClick={() => setShowMoveFolderDialog(false)}>{tComponents('FolderView.cancel')}</Button>
            <Button color='primary' variant='contained' onClick={() => confirmMoveFolders()}>
              {tComponents('FolderView.confirmMoveButton')}
            </Button>
          </Container>
        </Container>
      </Dialog>
    </>
  );
};

export default PageTree;
