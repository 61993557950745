import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldProps, Typography } from '@mui/material';
import { CommonTagEditingList } from '@/views/SettingsView/CommonTagEditingList';
import { useStore } from '../../../components/store/Store';
import { Api } from '../../../services/Api';
import SettingsViewLayout from '../../../components/SettingsViewLayout';
import { TagType } from '../../../declarations/models/TagType';

export interface NewKeywordInputProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  onChange: (e: string) => void;
  label: string;
  value: string;
}

export const SiteKeywordsSettings: FC = () => {
  const { t: tComponents } = useTranslation('components');
  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;

  const fetchMethod = useCallback(() => Api.getAllTagsForSite(selectedSiteId, { type: TagType.TAG }), [selectedSiteId]);

  return (
    <SettingsViewLayout
      title={tComponents('MainMenu.settings.keywords')}
      saveButtonInHeader={false}
      saveButtonInTop={false}>
      <Typography>{tComponents('Settings.SiteSettings.SiteKeywordsSettings.infoText')}</Typography>
      <CommonTagEditingList
        searchLabel={tComponents('Settings.SiteSettings.SiteKeywordsSettings.newKeyword')}
        getTagsMethod={fetchMethod}
        defaultNewTag={{
          type: TagType.TAG,
        }}
      />
    </SettingsViewLayout>
  );
};

export default SiteKeywordsSettings;
