/* istanbul ignore file */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { i18nextPlugin } from 'translation-check';
import { Settings } from '../Settings';

// Import all translation-files/namespaces:
// EN
import enCommon from './en/common.json';
import enComponents from './en/components.json';
import enValidation from './en/validation.json';
import enARIA from './en/aria.json';
// NO
import noCommon from './no/common.json';
import noComponents from './no/components.json';
import noValidation from './no/validation.json';
import noARIA from './no/aria.json';
// SV
import svCommon from './sv/common.json';
import svComponents from './sv/components.json';
import svValidation from './sv/validation.json';
import svARIA from './sv/aria.json';

export const AVAILABLE_LOCALES = ['en', 'no', 'sv'];
export const DEFAULT_LOCALE: 'en' | 'no' = 'en';

// Define all resources for each locale
const resources = {
  en: {
    common: enCommon,
    components: enComponents,
    validation: enValidation,
    aria: enARIA,
  },
  no: {
    common: noCommon,
    components: noComponents,
    validation: noValidation,
    aria: noARIA,
  },
  sv: {
    common: svCommon,
    components: svComponents,
    validation: svValidation,
    aria: svARIA,
  },
} as const;

// Create type for translation-keys
const defaultResource = resources[DEFAULT_LOCALE];
export type TranslationResourcesKeys = typeof defaultResource;

/**
 * Configures i18Next
 */
export async function configureI18N(testing = false): Promise<void> {
  let setup = i18n;

  if (!testing) {
    setup = setup.use(XHR).use(LanguageDetector);
    if (Settings.envIsDevelopment) {
      setup = setup.use(i18nextPlugin);
    }
  }

  await setup
    .use(intervalPlural)
    .use(initReactI18next)
    .init({
      lng: testing ? 'cimode' : undefined,
      fallbackLng: testing ? 'en' : DEFAULT_LOCALE,
      defaultNS: 'common',
      fallbackNS: ['component', 'validation', 'aria'],
      supportedLngs: AVAILABLE_LOCALES,
      debug: false,
      lowerCaseLng: true,
      interpolation: {
        escapeValue: false,
      },
      resources,
    });
}
