import React, { FC, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ChildrenProp } from '../declarations/ChildrenProp';
import Container from './Container';
import Styles from '../assets/js/Styles';

export interface SettingsInputContainerProps extends ChildrenProp {
  title?: string;
  anchor?: string;
}

export const SettingsInputContainer: FC<SettingsInputContainerProps> = ({ children, title, anchor }) => {
  const container = useRef<HTMLDivElement | null>(null);
  const { hash } = useLocation();
  const padding = title ? 2 : 2;

  useEffect(() => {
    if (anchor && hash === `#${anchor}`) {
      container.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [anchor, hash]);

  return (
    <Container
      ref={container}
      left
      fullWidth
      column
      gap={0}
      sx={{ backgroundColor: Styles.Colors.THEME_BG_COLOR, borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT }}>
      {title && (
        <Container left p={2} fullWidth sx={{ borderBottom: `1px solid ${Styles.Colors.THEME_BG_COLOR_SECONDARY}` }}>
          <Typography variant='subtitle1'>{title}</Typography>
        </Container>
      )}
      <Container p={padding} gap={4} fullWidth column left>
        {children}
      </Container>
    </Container>
  );
};

export default SettingsInputContainer;
