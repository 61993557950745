/**
 * Get the Vimeo-video-ID from a URL, if the provided URL is a URL to a vimeo-video
 * @param url
 */
export function getVimeoVideoIdFromUrl(url: string): string | null {
  if (!url) {
    return null;
  }
  const {
    channelVideoId,
    videoId,
    groupVideoId,
    playerVideoId,
  }: Partial<Record<'videoId' | 'channelVideoId' | 'groupVideoId' | 'playerVideoId', string>> =
    /^(?:https?:\/\/)?(vimeo\.com\/((?<videoId>\d+)|channels\/.+\/(?<channelVideoId>\d+)|groups\/.+\/(?<groupVideoId>\d+))|player\.vimeo\.com\/video\/(?<playerVideoId>\d+))$/gi.exec(
      url,
    )?.groups || {};
  return videoId || playerVideoId || channelVideoId || groupVideoId || null;
}

/**
 * Get the YouTube-video-ID from a URL, if the provided URL is a URL to a YouTube-video
 * @param url
 */
export function getYouTubeVideoIdFromUrl(url: string): string | null {
  if (!url) {
    return null;
  }
  const { videoId, watchVideoId, embedVideoId }: Partial<Record<'videoId' | 'watchVideoId' | 'embedVideoId', string>> =
    /^(?:https?:\/\/)?(?:www\.)?(?:(?:m\.)?youtube\.com|youtu\.be)\/((?<videoId>\w{11})|(watch\?v=(?<watchVideoId>\w{11}))|(embed\/(?<embedVideoId>\w{11})))$/gi.exec(
      url,
    )?.groups || {};
  return videoId || watchVideoId || embedVideoId || null;
}

/**
 * Get the URL to the Vimeo-player for the specified ID
 * @param vimeoVideoId
 */
export function getVimeoPlaybackUrl(vimeoVideoId: string | null): string | null {
  if (!vimeoVideoId || Number.isNaN(Number(vimeoVideoId))) {
    return null;
  }
  return `https://player.vimeo.com/video/${vimeoVideoId}`;
}

/**
 * Get the URL to the YouTube-player for the specified ID
 * @param youTubeId
 */
export function getYouTubePlaybackUrl(youTubeId: string | null): string | null {
  if (!youTubeId || youTubeId.length !== 11) {
    return null;
  }
  return `https://youtube.com/embed/${youTubeId}`;
}

/**
 *
 * @param youTubeId
 */
export function getYouTubeThumbnailURL(youTubeId: string | null): string | null {
  if (!youTubeId) {
    return null;
  }
  return `https://img.youtube.com/vi/${youTubeId}/default.jpg`;
}

/**
 *
 * @param vimeoId
 */
export function getVimeoThumbnailURL(vimeoId: string | null): string | null {
  if (!vimeoId) {
    return null;
  }
  // FIXME: This is not the correct URL. USE API instead?
  return `https://i.vimeocdn.com/video/${vimeoId}_1280x720`;
}

/**
 * FIXME: Improve/fix RegEx
 * Check if a string is a valid url
 *
 * @param urlInput - The input string
 */
export const isValidUrl = (urlInput?: string) => {
  if (!urlInput) return false;
  return /^(https:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i.test(urlInput);
};

const urlReplacements = new Map<string, string>([
  ['å', 'a'],
  ['æ', 'a'],
  ['ø', 'o'],
  ['ä', 'a'],
  ['ö', 'o'],
  [' ', '-'],
]);
const urlSafeCharacters = 'abcdefghijklmnopqrstuvwxyz0123456789-_.~';

export function urlSafeString(s?: string, additionalSafeCharacters?: string): string {
  const safe = urlSafeCharacters + (additionalSafeCharacters || '');
  return [...(s || '').toLowerCase()]
    .map((c) => {
      if (safe.includes(c)) {
        return c;
      }
      const replacement = urlReplacements.get(c);
      return replacement || '';
    })
    .join('');
}
