import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { useStore } from '@/components/store/Store';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { M24MediaModel } from '../../../../../declarations/models/M24MediaModel';
import Container from '../../../../../components/Container';
import Image from '../../../../../components/Image';
import CaptionInput from '../CaptionInput';
import { BlockItemPathPrefix } from '../../../../../declarations/models/BlockItemPathPrefix';
import { ChildrenProp } from '../../../../../declarations/ChildrenProp';

export interface M24ContentProps {
  item: M24MediaModel;
  pathPrefix: BlockItemPathPrefix;
  editMedia: () => void;
}

export const M24Content: FC<M24ContentProps & ChildrenProp> = ({ children, item, pathPrefix, editMedia }) => {
  const {
    state: { selectedSite },
  } = useStore();
  const { t } = useTranslation('components');
  const altText = item?.content?.alttext;

  let imgMimeType = '';
  if (item.mimetype?.includes('png')) {
    imgMimeType = `mediaType=${item.mimetype}`;
  }

  return (
    <Container p={2} column fullWidth>
      <Container gap={2} top spaceBetween fullWidth>
        <Container column sx={{ maxWidth: '200px' }} left fullWidth>
          <Image
            src={`${item.url}?${imgMimeType}&dimension=250x250`}
            title={item.title}
            alt={altText}
            maxWidth='200px'
            focusPoint={{
              x: item.x || item.local?.x || '0',
              y: item.y || item.local?.y || '0',
            }}
          />

          {!altText && (
            <Container px={1}>
              <MaterialSymbol name='error' fill color='error' />
              <Typography variant='caption' color='error'>
                {t('M24Content.MissingAltTextWarning')}
              </Typography>
            </Container>
          )}
          {item.site_id === selectedSite?.id && (
            <Button
              sx={{
                typography: 'caption',
                textAlign: 'left',
                '&:hover': { textDecoration: 'underline' },
              }}
              startIcon={<MaterialSymbol name='edit' fill />}
              onClick={editMedia}>
              {t('M24Content.EditGlobalMediaData')}
            </Button>
          )}
        </Container>

        <CaptionInput
          toggleCaptionVisibilityPath={`${pathPrefix}.local.disableCaption`}
          captionPath={`${pathPrefix}.local.caption`}
          placeholder={item?.content?.caption}
          hint={t('M24Content.CaptionHint')}
        />
      </Container>
      {/* {item.type === 'video' && (
  <Container px={1} fullWidth>
    <CheckboxInput
      path={`${pathPrefix}.local.portraitmode`}
      fullWidth={true}
      label={t('M24Content.ForcePortraitMode')}
    />
  </Container>
)} */}
      <Container px={1} fullWidth>
        {children}
      </Container>
      <Container px={1} right fullWidth>
        <Typography variant='caption'>M24-ID: {item.id}</Typography>
      </Container>
    </Container>
  );
};

export default M24Content;
