import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockPath } from '../../CurrentBlockProvider';
import { PageListViewType } from '../../../../declarations/models/blocks/PageListBlocks';

import { OutlinedContainer } from '../../../../components/OutlinedContainer';
import Styles from '../../../../assets/js/Styles';
import CheckboxInput from '../../../../components/forms/CheckboxInput';

export const PageListDisplayOptions: FC<{ blockPath: BlockPath; viewType: PageListViewType }> = ({
  blockPath,
  viewType,
}) => {
  const { t } = useTranslation('components');
  return (
    <>
      <OutlinedContainer
        p={2}
        label={`${t('PageListManualBlock.DisplayInputs.Label')}:`}
        fullWidth
        wrap
        sx={{
          justifyContent: 'flex-start!important',
          p: 1,
        }}
        labelBackgroundColor={Styles.Colors.THEME_BG_COLOR_SECONDARY}>
        {viewType !== PageListViewType.SIMPLE && (
          <CheckboxInput
            fullWidth={false}
            path={`${blockPath}.displayInsertTitle`}
            label={t('PageListManualBlock.DisplayInputs.displayInsertTitle')}
          />
        )}

        <CheckboxInput
          fullWidth={false}
          path={`${blockPath}.displayDescription`}
          label={t('PageListManualBlock.DisplayInputs.displayDescription')}
        />

        {viewType !== PageListViewType.SIMPLE && (
          <>
            <CheckboxInput
              fullWidth={false}
              path={`${blockPath}.displayCategory`}
              label={t('PageListManualBlock.DisplayInputs.displayCategory')}
            />
            <CheckboxInput
              fullWidth={false}
              path={`${blockPath}.displayPublishedDate`}
              label={t('PageListManualBlock.DisplayInputs.displayPublishedDate')}
            />
            <CheckboxInput
              fullWidth={false}
              path={`${blockPath}.displayLocation`}
              label={t('PageListManualBlock.DisplayInputs.displayLocation')}
            />
            <CheckboxInput
              fullWidth={false}
              path={`${blockPath}.displayMuseum`}
              label={t('PageListManualBlock.DisplayInputs.displayMuseum')}
            />
          </>
        )}
      </OutlinedContainer>
      {viewType !== PageListViewType.LIST && (
        <OutlinedContainer
          label={`${t('PageListAutoBlock.Adjustments')}:`}
          p={2}
          fullWidth
          sx={{
            backgroundColor: '#fff',
          }}>
          {viewType === PageListViewType.GRID && (
            <CheckboxInput path={`${blockPath}.highlightAll`} label={t(`PageListAutoBlock.Highlight`)} />
          )}

          <CheckboxInput path={`${blockPath}.grid33`} label={t(`PageListAutoBlock.ThreeCols`)} />

          {/* <CheckboxInput path={`${blockPath}.removeLeftPadding`} label={t(`PageListAutoBlock.RemoveLeftPadding`)} /> */}
        </OutlinedContainer>
      )}
    </>
  );
};

export default PageListDisplayOptions;
