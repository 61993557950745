import React, { FC } from 'react';
import TextInput from '@/components/forms/TextInput';
import { BlockPath, useCurrentBlock } from '@/editor/PageEditor/CurrentBlockProvider';
import { useTranslation } from 'react-i18next';
import SelectInput from '@/components/forms/SelectInput';
import SwitchInput from '@/components/forms/SwitchInput';
import { useWatch } from 'react-hook-form';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { BannerBlock } from '@/declarations/models/blocks/BannerBlock';
import Container from '@/components/Container';
import { FormHelperText } from '@mui/material';
import Grid from '@mui/material/Grid';
import { BannerIconAndSoundSettings } from './BannerIconAndSoundSettings';

export const BannerBlockAdvancedSettings: FC = () => {
  const { blockPath } = useCurrentBlock();
  const { t } = useTranslation('components');

  const includeDMSearch = useWatch<BlockSpecificPage<BannerBlock>, `${BlockPath}.dmSearch`>({
    name: `${blockPath}.dmSearch`,
  });

  const effectOptions = ['', 'zoom'];
  // 'parallax'

  return (
    <Container fullWidth left column gap={2}>
      <BannerIconAndSoundSettings path={blockPath} />
      <Container fullWidth column left gap={0} mt={2}>
        <SelectInput
          hideNoSelectionOption
          defaultValue=''
          options={effectOptions}
          getOptionKey={(effect) => `effect-${effect}`}
          getOptionLabel={(effect) => `${t(`BannerBlock.AdvancedSettings.effect.options.${effect}`)}`}
          path={`${blockPath}.effect`}
          label={t('BannerBlock.AdvancedSettings.effect.Label')}
        />
        <FormHelperText
          sx={{
            ml: '1rem',
          }}>
          {t('BannerBlock.AdvancedSettings.effect.HelperText')}
        </FormHelperText>
      </Container>

      <SwitchInput
        fullWidth={false}
        path={`${blockPath}.dmSearch`}
        label={t('BannerBlock.AdvancedSettings.dmSearch.Label')}
      />

      {includeDMSearch && (
        <>
          <Container fullWidth column left gap={0} mt={2}>
            <TextInput
              path={`${blockPath}.dmQueryString.completeUrl`}
              label={t('BannerBlock.AdvancedSettings.dmSearch.completeUrl.Label')}
              placeholder={t('BannerBlock.AdvancedSettings.dmSearch.completeUrl.Placeholder')}
            />
            <FormHelperText
              sx={{
                ml: '1rem',
              }}>
              {t('BannerBlock.AdvancedSettings.dmSearch.completeUrl.HelperText')}
            </FormHelperText>
          </Container>
          <Grid container columnSpacing={1}>
            <Grid item xs={7}>
              <TextInput
                path={`${blockPath}.dmSearchSettings.labelPlaceholder`}
                label={t('BannerBlock.AdvancedSettings.dmSearch.labelPlaceholder.Label')}
                placeholder={t('BannerBlock.AdvancedSettings.dmSearch.labelPlaceholder.Placeholder')}
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                path={`${blockPath}.dmSearchSettings.labelButton`}
                label={t('BannerBlock.AdvancedSettings.dmSearch.labelButton.Label')}
                placeholder={t('BannerBlock.AdvancedSettings.dmSearch.labelButton.Placeholder')}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Container column fullWidth mt={2}>
        <TextInput
          path={`${blockPath}.svgLogo`}
          label={t('BannerBlock.AdvancedSettings.svgLogo.Label')}
          helperText={t('BannerBlock.AdvancedSettings.svgLogo.HelperText')}
          multiline
          minRows={3}
        />
        <TextInput
          path={`${blockPath}.tocId`}
          label={t('BannerBlock.AdvancedSettings.tocId.Label')}
          helperText={t('BannerBlock.AdvancedSettings.css')}
        />
        <TextInput path={`${blockPath}.cssClass`} label='CSS klasse' />
      </Container>
    </Container>
  );
};
