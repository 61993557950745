import React from 'react';
import { BlockPreviewComponent } from '@/editor/lib/declarations/EditorComponentTypes';
import { type MapV2Block as MapV2BlockType } from '@/declarations/models/blocks/MapV2';
import { useCurrentBlock } from '../CurrentBlockProvider';
import MapPlaceholder from '../../../assets/images/mjosa_map.png';

export const MapV2BlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as MapV2BlockType;

  return (
    <>
      <div className={`article__grid alignmodify--${currentBlock?.width}`}>
        <section className='article__bodytext'>
          {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
          <p>{currentBlock?.body}</p>
        </section>
      </div>
      <div className='section__grid'>
        <div className={`map map--${currentBlock?.width || 'auto'}`}>
          <div className='preview__map'>
            <div>
              Her vil det vises et kart med punkter.
              <br /> Se endelig resultat i portal.
            </div>
            <img style={{}} src={MapPlaceholder} alt='map' />
          </div>
        </div>
      </div>
    </>
  );
};
