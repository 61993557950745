import React, { FC, useState } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { M24PageFinderProps, useM24PageFinderContentView } from '@/components/Finder/M24PageFinder/M24PageFinder';
import { useDebounce } from '@/hooks/useDebounce';
import { FinderHeader } from '@/components/Finder/FinderHeader';
import { SharedSiteSelector } from '@/components/Finder/SharedSiteSelector';
import { Site } from '@/declarations/models/Site';
import Styles from '@/assets/js/Styles';
import { SharingContentType } from '@/declarations/models/SiteSharingModel';
import { LocationSelector } from '@/components/LocationSelector';
import { EventCategory, SubCategory } from '@/declarations/Category';
import Container from '../../../Container';

export const M24PageFinderPageTreeToolbar: FC<{
  isEvent?: boolean;
  site: Site | null;
  selectedSharedSite: Site | null;
  onSelectSharedSite: (s: Site | null) => void;
  selectedLanguage: string | null;
  onSelectLanguage: (l: string | null) => void;
  hideSiteSelector?: boolean;
  disableSelectors?: boolean;
  selectorType: M24PageFinderProps['type'];
}> = ({
  isEvent,
  site,
  selectedSharedSite,
  onSelectSharedSite,
  selectedLanguage,
  onSelectLanguage,
  hideSiteSelector,
  disableSelectors,
  selectorType,
}) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const [query, setQuery] = useState<string>('');
  const { filterValues, setFilterValues } = useM24PageFinderContentView();
  useDebounce(300, query, (debouncedValue) => setFilterValues((prev) => ({ ...prev, query: debouncedValue })));

  function titleText() {
    if (isEvent) {
      return tComponents('Finder.EventsTitle');
    }
    if (selectorType === 'template') {
      return tComponents('Finder.TemplatesTitle');
    }
    return tComponents('Finder.PageTitle');
  }

  function sortEventCategoryByLabelName(a: SubCategory, b: SubCategory) {
    const aLabel = tCommon(`SubCategory.${a}`);
    const bLabel = tCommon(`SubCategory.${b}`);
    return aLabel.localeCompare(bLabel);
  }

  return (
    <FinderHeader
      title={<Typography sx={{ fontSize: 'xx-large', fontWeight: 500 }}>{titleText()}</Typography>}
      onQueryChange={setQuery}
      topRight={
        <Container right>
          {!hideSiteSelector && (
            <SharedSiteSelector
              disabled={disableSelectors}
              siteContext={site}
              selectedSite={selectedSharedSite}
              setSelectedSite={onSelectSharedSite}
              contentType={selectorType === 'template' ? SharingContentType.TEMPLATES : SharingContentType.CONTENT}
            />
          )}

          <Select
            disabled={disableSelectors}
            variant='outlined'
            size='small'
            SelectDisplayProps={{ style: { backgroundColor: 'background.paper' } }}
            value={selectedLanguage}
            onChange={(e) => onSelectLanguage(e.target.value as string)}
            sx={{ minWidth: 100, borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT }}>
            {site?.site_contents?.map((c) => (
              <MenuItem key={c.locale} value={c.locale}>
                {c.locale.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </Container>
      }
      bottomRight={
        isEvent ? (
          <>
            <LocationSelector
              size='small'
              fullWidth={false}
              sx={{
                minWidth: 200,
                maxWidth: 450,
              }}
              value={filterValues.location_ids}
              onChange={(value) => setFilterValues((prev) => ({ ...prev, location_ids: value }))}
              siteId={(selectedSharedSite ?? site)?.id}
            />
            <FormControl
              variant='outlined'
              size='small'
              sx={{
                minWidth: '200px',
                maxWidth: '300px',
              }}>
              <InputLabel>{tComponents('PageTreeToolbar.subCategoryLabel')}</InputLabel>
              <Select
                multiple
                value={filterValues.sub_categories}
                onChange={(e) =>
                  setFilterValues((prev) => {
                    return { ...prev, sub_categories: e.target.value as SubCategory[] };
                  })
                }
                input={<OutlinedInput />}
                renderValue={(selected) => selected.map((category) => tCommon(`SubCategory.${category}`)).join(', ')}>
                {Object.values(EventCategory)
                  .sort(sortEventCategoryByLabelName)
                  .map((category) => (
                    <MenuItem key={category} value={category}>
                      <Checkbox checked={filterValues.sub_categories!.includes(category)} />
                      <ListItemText primary={tCommon(`SubCategory.${category}`)} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </>
        ) : null
      }
    />
  );
};

export default M24PageFinderPageTreeToolbar;
