import Container from '@/components/Container';
import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useStore } from '@/components/store/Store';

export const StylePreviewWidget = () => {
  const {
    state: { selectedSiteSkins },
  } = useStore();

  const colors = useMemo(() => {
    if (!selectedSiteSkins.length) return [] as string[];
    return selectedSiteSkins.reduce((acc, skin) => {
      if (skin.hex && !acc.includes(skin.hex)) {
        return [...acc, skin.hex];
      }
      return acc;
    }, [] as string[]);
  }, [selectedSiteSkins]);

  // console.log('selectedSiteSkins', selectedSiteSkins);
  // console.log('colors', colors);
  return (
    <Container
      column
      top
      left
      fullWidth
      fullHeight
      sx={{
        minHeight: '150px',
      }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}>
        {colors.map((color) => (
          <Box
            key={color}
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: color,
            }}
          />
        ))}
      </div>
    </Container>
  );
};
