import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../components/store/Store';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import Container from '../../../components/Container';
import SwitchInput from '../../../components/forms/SwitchInput';
import TextInput from '../../../components/forms/TextInput';

export interface LanguageMenuConsolidatedSettingsProps {
  blockPath: BlockPath;
}

export const LanguageMenuConsolidatedSettings: FC<LanguageMenuConsolidatedSettingsProps> = ({ blockPath }) => {
  const { t } = useTranslation('components');
  const { state } = useStore();
  const isSuperAdmin = state.currentUser?.user?.is_super_admin;

  return (
    <Container fullWidth top left>
      <Container column fullWidth top left gap={3}>
        <Container fullWidth top left>
          <SwitchInput
            path={`${blockPath}.includeParent`}
            label={t('Settings.LanguageSettings.LanguageMenuSettings.includeConsolidatedLogo')}
            defaultValue={false}
          />
          <SwitchInput
            path={`${blockPath}.includeParentLine`}
            label={t('Settings.LanguageSettings.LanguageMenuSettings.includeConsolidatedLine')}
          />
        </Container>

        <TextInput
          path={`${blockPath}.parentName`}
          label={t('Settings.LanguageSettings.LanguageMenuSettings.consolidatedName')}
          defaultValue=''
        />
        <TextInput
          path={`${blockPath}.parentLink`}
          label={t('Settings.LanguageSettings.LanguageMenuSettings.consolidatedLink')}
          defaultValue=''
        />
        <TextInput
          path={`${blockPath}.parentLogo`}
          label={t('Settings.LanguageSettings.LanguageMenuSettings.consolidatedLogo')}
          defaultValue=''
          multiline
          rows={5}
        />
        <TextInput
          path={`${blockPath}.parentLinkText`}
          label={t('Settings.LanguageSettings.LanguageMenuSettings.consolidatedLine')}
          defaultValue=''
        />
      </Container>
    </Container>
  );
};

export default LanguageMenuConsolidatedSettings;
