import React, { useEffect, useState } from 'react';
import { SnackbarContentProps, useSnackbar } from 'notistack';
import { Card, CardActions, CardContent, IconButton, Typography, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Container from '@/components/Container';
import { useTranslation } from 'react-i18next';

interface ExpandableSnackbarProps extends SnackbarContentProps {
  message: string;
  expandedContent: string;
  variant: 'default' | 'error' | 'success' | 'warning' | 'info' | 'expandableError';
}

const ExpandableSnackbar = React.forwardRef<HTMLDivElement, ExpandableSnackbarProps>((props, ref) => {
  const { id, message, expandedContent, variant } = props;
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [timestamp, setTimestamp] = useState('');
  const { closeSnackbar } = useSnackbar();
  const { t: tCommon } = useTranslation('common');

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    closeSnackbar(id);
  };

  useEffect(() => {
    // Generate the current timestamp when the component mounts
    const now = new Date();
    const formattedTimestamp = now.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'longGeneric',
      timeZone: 'Europe/Oslo',
      hour12: false,
    });
    setTimestamp(formattedTimestamp);
  }, []);

  const backgroundColor = variant === 'expandableError' ? theme.palette.error.main : theme.palette.background.paper;
  const textColor = theme.palette.getContrastText(backgroundColor);

  return (
    <Container sx={{ maxWidth: '300px', display: 'flex' }}>
      <Card ref={ref} sx={{ backgroundColor, color: textColor, minWidth: '400px' }}>
        <CardActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
          <Container fullWidth>
            <Container>
              <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                {message}
              </Typography>
            </Container>
            <Container sx={{ marginLeft: 'auto' }}>
              <IconButton onClick={handleClose} aria-label='close' sx={{ color: textColor }}>
                <CloseIcon />
              </IconButton>
            </Container>
          </Container>
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
            sx={{ color: textColor, alignSelf: 'flex-start', paddingLeft: '0' }}>
            <Typography variant='body2' sx={{ wordBreak: 'break-word' }}>
              {tCommon('ShowMore')}
            </Typography>
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent sx={{ color: textColor, paddingLeft: '0.5em' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '8px' }}>
              <Typography variant='body2' sx={{ wordBreak: 'break-word' }}>
                {tCommon('screenshotInstructionErrorMessage')}
              </Typography>
              {timestamp && (
                <Typography variant='body2' sx={{ wordBreak: 'break-word', marginRight: '8px' }}>
                  Timestamp: {timestamp}
                </Typography>
              )}
              <Typography variant='body2' sx={{ wordBreak: 'break-word' }}>
                {expandedContent}
              </Typography>
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </Container>
  );
});

ExpandableSnackbar.displayName = 'ExpandableSnackbar';

export default ExpandableSnackbar;
