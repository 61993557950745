import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDebounce } from '@/hooks/useDebounce';
import Container from '@/components/Container';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SortDirection, SortOption, SortType } from '@/declarations/models/SortOption';
import { Tag } from '@/declarations/models/Tag';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useStore } from '@/components/store/Store';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { useApi } from '@/hooks/useApi';
import { Api } from '@/services/Api';
import Loader from '@/components/Loader';
import Styles from '@/assets/js/Styles';
import { RequestContext } from '@/utils/ApiRequest/RequestContext';

const SearchAndAddInput: FC<{
  onQueryChange: (query: string) => void;
  onAdd: (name: string) => void;
  disabledAdd: boolean;
  searchLabel?: string;
}> = ({ onQueryChange, onAdd, disabledAdd, searchLabel }) => {
  const { t: tCommon } = useTranslation('common');
  const [value, setValue] = useState<string>('');
  useDebounce(400, value, onQueryChange);

  return (
    <Container fullWidth left>
      <TextField
        sx={{
          flexGrow: 8,
        }}
        autoComplete='off'
        label={searchLabel || tCommon('search')}
        variant='outlined'
        color='secondary'
        value={value}
        onChange={(e) => setValue(e.target.value || '')}
      />

      <Button
        variant='outlined'
        startIcon={<MaterialSymbol name='add' />}
        onClick={() => {
          onAdd(value);
          setValue('');
        }}
        disabled={!value || disabledAdd}
        sx={{
          height: '100%',
          flexGrow: 1,
        }}>
        {tCommon('add')}
      </Button>
    </Container>
  );
};

const sortOptions: Array<SortOption> = [
  {
    sortBy: SortType.TITLE,
    order: SortDirection.ASC,
  },
  {
    sortBy: SortType.TITLE,
    order: SortDirection.DESC,
  },
  {
    sortBy: SortType.CREATED_AT,
    order: SortDirection.ASC,
  },
  {
    sortBy: SortType.CREATED_AT,
    order: SortDirection.DESC,
  },
];

function sortKeywordsAlphabetically(keywords: Array<Tag>, order: SortDirection) {
  return keywords?.sort((a, b) => {
    const titleA = (a?.tag || '').toLowerCase();
    const titleB = (b?.tag || '').toLowerCase();
    if (titleA < titleB) {
      return order === SortDirection.ASC ? -1 : 1;
    }
    if (titleA > titleB) {
      return order === SortDirection.ASC ? 1 : -1;
    }
    return 0;
  });
}

function sortKeywordsByCreationDate(keywords: Array<Tag>, order: SortDirection) {
  return keywords?.sort((a, b) => {
    if (a?.created_at) {
      if (b?.created_at) {
        if (a?.created_at < b?.created_at) {
          return order === SortDirection.ASC ? -1 : 1;
        }
        if (a?.created_at > b?.created_at) {
          return order === SortDirection.ASC ? 1 : -1;
        }
      }
      return -1;
    }
    return 0;
  });
}

function sortKeywords(keywords: Array<Tag>, sortOption: SortOption) {
  switch (sortOption.sortBy) {
    case SortType.TITLE:
      return sortKeywordsAlphabetically(keywords, sortOption.order);
    case SortType.CREATED_AT:
      return sortKeywordsByCreationDate(keywords, sortOption.order);
  }
  return keywords;
}

const EditableString: FC<{
  initialValue: string;
  onChange: (value: string) => void;
  onDelete: () => void;
}> = ({ initialValue, onChange, onDelete }) => {
  const [value, setValue] = useState<string>(initialValue);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { t } = useTranslation('common');

  const hasChanges = useMemo(() => value !== initialValue, [value, initialValue]);

  return !isEditing ? (
    <Button
      onClick={() => setIsEditing(true)}
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
        py: 2,
        px: 2,
      }}>
      <Typography>{value}</Typography>
    </Button>
  ) : (
    <Container fullWidth left top>
      <TextField
        fullWidth
        label={t('name')}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <Tooltip title={t('save')}>
              <span>
                <IconButton
                  onClick={() => {
                    setIsEditing(false);
                    onChange(value);
                  }}
                  disabled={!hasChanges || !value}
                  sx={{
                    height: '100%',
                  }}
                  color={!!value && hasChanges ? 'success' : 'default'}>
                  <MaterialSymbol name='save' color='inherit' />
                </IconButton>
              </span>
            </Tooltip>
          ),
        }}
      />
      <Tooltip title={t('cancel')}>
        <IconButton
          onClick={() => {
            setValue(initialValue);
            setIsEditing(false);
          }}>
          <MaterialSymbol name='cancel' />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('delete')}>
        <IconButton
          onClick={() => {
            onDelete();
          }}>
          <MaterialSymbol name='delete' fill color='error' />
        </IconButton>
      </Tooltip>
    </Container>
  );
};

export interface CommonTagEditingListProps {
  getTagsMethod: () => RequestContext<{ tags: Tag[] }, Tag[]>;
  postFetchPredicates?: Array<(tags: Tag) => boolean>;
  defaultNewTag: Partial<Tag>;
  searchLabel?: string;
}

export const CommonTagEditingList: FC<CommonTagEditingListProps> = ({
  getTagsMethod,
  postFetchPredicates = [],
  defaultNewTag,
  searchLabel,
}) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useStore();
  const confirm = useConfirmDialog();
  const selectedSiteId = state.selectedSite?.id || 0;
  const [allCategories, isLoadingAllCategories, reloadAllCategories] = useApi(getTagsMethod, []);
  useEffect(() => {
    reloadAllCategories();
  }, [reloadAllCategories, selectedSiteId]);

  const [query, setQuery] = useState<string>('');
  const [sortOption, setSortOption] = useState<SortOption>({ sortBy: SortType.TITLE, order: SortDirection.ASC });
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const filterededAndSortedCategories = useMemo(() => {
    const sorted = sortKeywords(allCategories ?? [], sortOption).filter((category) =>
      postFetchPredicates.every((predicate) => predicate(category)),
    );
    if (query) {
      return sorted.filter((category) => category.tag.toLowerCase().includes(query.toLowerCase()));
    }
    return sorted;
  }, [allCategories, postFetchPredicates, query, sortOption]);

  const onAddCategory = async (name: string) => {
    setIsSaving(true);
    await Api.saveTag(selectedSiteId, {
      tag: name,
      ...defaultNewTag,
    }).fetchDirect(null);
    reloadAllCategories();
    setIsSaving(false);
    setQuery('');
    enqueueSnackbar(tCommon('saved'), { variant: 'success' });
  };

  const onUpdateCategory = async (category: Tag) => {
    setIsSaving(true);
    await Api.saveTag(selectedSiteId, category).fetchDirect(null);
    reloadAllCategories();
    setIsSaving(false);
    enqueueSnackbar(tCommon('saved'), { variant: 'success' });
  };

  const onDeleteCategory = async (category: Tag) => {
    if (!(await confirm(tComponents('Settings.SiteSettings.SiteKeywordsSettings.deleteConfirm')))) {
      return;
    }
    setIsSaving(true);
    await Api.deleteTag(selectedSiteId, category).fetchDirect(null);
    reloadAllCategories();
    setIsSaving(false);
    enqueueSnackbar(tCommon('saved'), { variant: 'success' });
  };

  return (
    <Container column fullWidth left top py={2} gap={2}>
      <SearchAndAddInput
        searchLabel={searchLabel}
        disabledAdd={isSaving}
        onQueryChange={setQuery}
        onAdd={onAddCategory}
      />

      <FormControl fullWidth>
        <InputLabel color='secondary'>{tComponents('Settings.SiteSettings.SiteKeywordsSettings.sorting')}</InputLabel>
        <Select
          value={sortOptions.findIndex(
            (option) => option.sortBy === sortOption.sortBy && option.order === sortOption.order,
          )}
          onChange={(event) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const selectedSortOption = sortOptions[event.target.value];
            setSortOption(selectedSortOption);
          }}>
          {sortOptions.map((option, index) => (
            <MenuItem key={`${option.sortBy} - ${option.order}`} value={index}>
              {tCommon(`SortOptions.${option.sortBy}_${option.order}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Container fullWidth left top column gap={2}>
        {isLoadingAllCategories && (
          <Container fullWidth fullHeight>
            <Loader size={25} />
          </Container>
        )}
        <List
          sx={{
            width: '100%',
          }}>
          {filterededAndSortedCategories.map((category) => (
            <ListItem
              key={`${category.id}-${category.tag}`}
              sx={{
                height: '5em',
                '&:not(:last-child)': {
                  borderBottom: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                },
              }}>
              <EditableString
                initialValue={category.tag}
                onChange={(val) => onUpdateCategory({ ...category, tag: val })}
                onDelete={() => onDeleteCategory(category)}
              />
            </ListItem>
          ))}
        </List>
      </Container>
    </Container>
  );
};
