import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CustomIcon from '@/components/CustomIcon';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import ActionMenu from '../../../components/ActionMenu';
import { EventsBlock, EventsLayout, EventsViewType } from '../../../declarations/models/blocks/EventsBlocks';

const LAYOUT_OPTIONS: Array<EventsLayout> = Object.values(EventsLayout);
const LAYOUT_OPTIONS_ICONS: Record<EventsLayout | 'img', ReactNode> = {
  img: <CustomIcon name='banner_image_above' />,
  [EventsLayout.IMAGE_TOP]: <CustomIcon name='banner_image_above' />,
  [EventsLayout.IMAGE_TOP_NO_MARGIN]: <MaterialSymbol name='horizontal_split' sx={{ transform: 'scaleY(-1)' }} />,
};

export const PageBlockEventListLayoutSettings: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { block, blockPath } = useCurrentBlock();
  const currentBlock = block as EventsBlock;
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<EventsBlock>, `${typeof blockPath}.layout`>({
    name: `${blockPath}.layout`,
  });

  return (
    <ActionMenu
      id='page-list-layout-selector'
      ariaLabel={tAria('components.PageBlockPageListLayoutSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockPageListLayoutSetting.Tooltip')}
      icon={value ? LAYOUT_OPTIONS_ICONS[value as EventsLayout] : LAYOUT_OPTIONS_ICONS['img-top']}
      disabled={currentBlock.view === EventsViewType.LIST}>
      {LAYOUT_OPTIONS.map((layoutOption) => (
        <MenuItem
          key={layoutOption}
          onClick={() => onChange(layoutOption)}
          selected={value === layoutOption}
          aria-label={tComponents(`PageBlockPageListLayoutSetting.options.${layoutOption}`)}>
          <ListItemIcon>{LAYOUT_OPTIONS_ICONS[layoutOption]}</ListItemIcon>
          <ListItemText>{tComponents(`EventListLayouts.${layoutOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};
