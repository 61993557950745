import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useControlledFieldArray } from '@/hooks/useControlledFieldArray';
import { BlockComponent } from '../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../CurrentBlockProvider';
import Links from '../../../components/Links/Links';
import { LinkType } from '../../../declarations/models/LinkType';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import Container from '../../../components/Container';
import Styles from '../../../assets/js/Styles';
import { LinkListBlock as LinkListBlockModel } from '../../../declarations/models/blocks/LinkListBlock';
import SwitchInput from '../../../components/forms/SwitchInput';
import { useEditorEventHandler, useEditorEventManager } from '../../lib/eventManager/EditorEventManager';

export const LinkListBlock: BlockComponent = () => {
  const { blockPath, block } = useCurrentBlock();
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const currentBlock = block as LinkListBlockModel;

  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState<boolean>(false);

  const {
    fields: linkItems,
    append,
    prepend,
    update,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<LinkListBlockModel>, `${BlockPath}.items`>({
    name: `${blockPath}.items`,
  });

  type SortType = false | '_title' | '_date';
  type GroupType = false | 'alpha';
  const ORDER_BY_OPTIONS: Array<SortType> = [false, '_title', '_date'];
  const GROUP_BY_OPTIONS: Array<GroupType> = [false, 'alpha'];

  const {
    field: { value: orderBy, onChange: onChangeOrderBy },
  } = useController<BlockSpecificPage<LinkListBlockModel>, `${typeof blockPath}.orderBy`>({
    name: `${blockPath}.orderBy`,
  });

  const {
    field: { value: groupBy, onChange: onChangeGroupBy },
  } = useController<BlockSpecificPage<LinkListBlockModel>, `${typeof blockPath}.groupBy`>({
    name: `${blockPath}.groupBy`,
  });

  const eventManager = useEditorEventManager();

  return (
    <Container gap={2} column top left fullWidth>
      <Links
        linkTypes={[LinkType.PAGE, LinkType.EVENT, LinkType.DOCUMENT, LinkType.LINK]}
        onUpdate={update}
        onAppend={append}
        onRemove={remove}
        onReorder={move}
        linkItems={linkItems}
        showListChildrenInput
        showLinkDescriptionField={currentBlock?.view === 'teasers'}
        blockPath={blockPath}
        useEventHandler={useEditorEventHandler}
        useEventManager={eventManager}
      />
      <Container left column fullWidth>
        <Button
          variant='text'
          startIcon={
            <MaterialSymbol
              name='chevron_right'
              sx={{
                transform: advancedSettingsOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'transform 0.1s ease-in-out',
              }}
            />
          }
          onClick={() => setAdvancedSettingsOpen((o) => !o)}>
          <Typography
            sx={{
              typography: 'subtitle1',
              fontWeight: 'bold',
              '&, &:hover': {
                textDecoration: 'underline',
              },
            }}>
            {`${advancedSettingsOpen ? `${tCommon('close')} : ` : ''} ${tComponents(
              'LinkListBlock.AdvancedSettings.Title',
            )}`}
          </Typography>
        </Button>
        <Collapse in={advancedSettingsOpen} sx={{ width: '100%' }}>
          <Paper>
            <Container
              sx={{ backgroundColor: Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR }}
              p={2}
              mt={2}
              mb={4}
              gap={2}
              column
              top
              left
              fullWidth>
              <FormControl fullWidth>
                <FormLabel color='secondary'>{tComponents('LinkListBlock.AdvancedSettings.OrderBy.Label')}</FormLabel>
                <RadioGroup
                  value={orderBy || false}
                  onClick={(e) => e.stopPropagation()}
                  row
                  onChange={(event, value) => {
                    onChangeOrderBy(value === 'false' ? false : value);
                  }}>
                  {ORDER_BY_OPTIONS.map((orderByOption) => (
                    <FormControlLabel
                      key={orderByOption?.toString()}
                      control={<Radio size='small' />}
                      label={tComponents(`LinkListBlock.AdvancedSettings.OrderBy.${orderByOption}`)}
                      value={orderByOption}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth>
                <FormLabel color='secondary'>{tComponents('LinkListBlock.AdvancedSettings.GroupBy.Label')}</FormLabel>
                <RadioGroup
                  value={groupBy || false}
                  onClick={(e) => e.stopPropagation()}
                  row
                  onChange={(_event, value) => {
                    onChangeGroupBy(value === 'false' ? false : value);
                  }}>
                  {GROUP_BY_OPTIONS.map((groupByOption) => (
                    <FormControlLabel
                      key={groupByOption?.toString()}
                      control={<Radio size='small' />}
                      label={tComponents(`LinkListBlock.AdvancedSettings.GroupBy.${groupByOption}`)}
                      value={groupByOption}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              <SwitchInput
                fullWidth={false}
                path={`${blockPath}.showDate`}
                label={tComponents('LinkListBlock.AdvancedSettings.showDate')}
              />

              <SwitchInput
                fullWidth={false}
                path={`${blockPath}.reverseOrder`}
                label={tComponents('LinkListBlock.AdvancedSettings.reverseOrder')}
              />
            </Container>
          </Paper>
        </Collapse>
      </Container>
    </Container>
  );
};

export default LinkListBlock;
