import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../components/store/Store';
import SettingsInputContainer from '../../../components/SettingsInputContainer';
import Container from '../../../components/Container';
import TextInput from '../../../components/forms/TextInput';
import CheckboxInput from '../../../components/forms/CheckboxInput';

export const WebpageEKulturIntegrationsSettings: FC = () => {
  const { t } = useTranslation('components');
  const { state } = useStore();
  const isSuperAdmin = state.currentUser?.user?.is_super_admin;

  return (
    <>
      <SettingsInputContainer title={t(`Settings.WebpageSettings.WebpageEKulturIntegrationsSettings.digitaltMuseum`)}>
        <Container left fullWidth>
          <Container column left sx={{ width: '50%' }}>
            <Typography variant='subtitle2'>
              {t(`Settings.WebpageSettings.WebpageEKulturIntegrationsSettings.dmOwners`)}
            </Typography>
            <TextInput path='content.settings.dmOwners' defaultValue='' label='' />
          </Container>
          {isSuperAdmin && (
            <Container column left sx={{ width: '50%' }}>
              <div className='superadmin' style={{ backgroundColor: '#fbe6ff', padding: '1em', width: '100%' }}>
                <CheckboxInput
                  fullWidth={false}
                  path='content.settings.disableDMmapPin'
                  label={t('Settings.WebpageSettings.WebpageIconsSettings.disableDMmapPin')}
                />
              </div>
            </Container>
          )}
        </Container>
      </SettingsInputContainer>

      <SettingsInputContainer title={t(`Settings.WebpageSettings.WebpageEKulturIntegrationsSettings.kulturPunkt`)}>
        <Container left fullWidth>
          <Container column left sx={{ width: '50%' }}>
            <Typography variant='subtitle2'>
              {t(`Settings.WebpageSettings.WebpageEKulturIntegrationsSettings.kulturpunktOwnerID`)}
            </Typography>
            <TextInput path='content.settings.kulturpunktOwnerID_v3' defaultValue='' label='' />
          </Container>
          {isSuperAdmin && (
            <Container column left sx={{ width: '50%' }}>
              <div className='superadmin' style={{ backgroundColor: '#fbe6ff', padding: '1em', width: '100%' }}>
                <CheckboxInput
                  fullWidth={false}
                  path='content.settings.disableKPmapPin'
                  label={t('Settings.WebpageSettings.WebpageIconsSettings.disableKPmapPin')}
                />
              </div>
            </Container>
          )}
        </Container>
      </SettingsInputContainer>
    </>
  );
};

export default WebpageEKulturIntegrationsSettings;
