import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/components/store/Store';
import Container from '@/components/Container';
import { Box, Typography } from '@mui/material';
import { ChildrenProp } from '../declarations/ChildrenProp';

export const StylesView: FC<ChildrenProp> = () => {
  const { t: tComponents } = useTranslation('components');
  const {
    state: { selectedSiteSkins, selectedSiteThemes },
  } = useStore();
  return (
    <Container left fullWidth p={2} top mt={2} column>
      <Typography my={2} variant='h1' fontSize='xx-large'>
        {tComponents('MainMenu.styles')}
      </Typography>

      <Container left fullWidth top mt={2}>
        <Typography fontWeight={700}>SKINS</Typography>
      </Container>
      <Container left wrap>
        {selectedSiteSkins.map((skin) => (
          <Box
            key={skin.class}
            sx={{
              width: '200px',
              height: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: skin.hex,
              color: skin.txt,
            }}>
            <Box
              sx={{
                textAlign: 'center',
              }}>
              <strong>{skin.title}</strong>
              <Typography my={1} fontSize='12px'>
                .{skin.class}
                <br />
                {skin.hex} <br />
                <Box sx={{ backgroundColor: skin.rgb }}>{skin.rgb}</Box>
              </Typography>
            </Box>
          </Box>
        ))}
      </Container>
      <Container left fullWidth top mt={2}>
        <Typography fontWeight={700}>THEMES</Typography>
      </Container>
      <Container left wrap>
        {selectedSiteThemes.map((skin) => (
          <Box
            key={skin.class}
            sx={{
              width: '200px',
              height: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: skin.hex,
              color: skin.txt,
            }}>
            <Box>{skin.title}</Box>
          </Box>
        ))}
      </Container>
    </Container>
  );
};

export default StylesView;
