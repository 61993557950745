import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CustomIcon from '@/components/CustomIcon';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { useCurrentBlock } from '../CurrentBlockProvider';
import ActionMenu from '../../../components/ActionMenu';
import { KPBlockModel, KPViewType, KPLayout } from '../../../declarations/models/blocks/KPBlock';

const LAYOUT_OPTIONS: Array<KPLayout> = Object.values(KPLayout);
const LAYOUT_OPTIONS_ICONS: Record<KPLayout, ReactNode> = {
  [KPLayout.IMAGE_TOP]: <CustomIcon name='banner_image_above' />,
  [KPLayout.TEXT_ON_IMAGE]: <MaterialSymbol name='article' />,
};

export const PageBlockKPLayoutSelector: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { block, blockPath } = useCurrentBlock();
  const currentBlock = block as KPBlockModel;

  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<KPBlockModel>, `${typeof blockPath}.layout`>({
    name: `${blockPath}.layout`,
  });

  return (
    <ActionMenu
      id='page-list-layout-selector'
      ariaLabel={tAria('components.PageBlockPageListLayoutSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockPageListLayoutSetting.Tooltip')}
      icon={value ? LAYOUT_OPTIONS_ICONS[value as KPLayout] : LAYOUT_OPTIONS_ICONS['img-top']}
      disabled={currentBlock.view === KPViewType.LIST}>
      {LAYOUT_OPTIONS.map((layoutOption) => (
        <MenuItem
          key={layoutOption}
          onClick={() => onChange(layoutOption)}
          selected={value === layoutOption}
          aria-label={tComponents(`PageBlockPageListLayoutSetting.options.${layoutOption}`)}>
          <ListItemIcon>{LAYOUT_OPTIONS_ICONS[layoutOption]}</ListItemIcon>
          <ListItemText>{tComponents(`PageBlockPageListLayoutSetting.options.${layoutOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default PageBlockKPLayoutSelector;
