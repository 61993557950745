import React from 'react';
import { LinkTarget } from '@/declarations/models/LinkExternal';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { BannerBlock } from '../../../declarations/models/blocks/BannerBlock';
import LinksPreview from './LinksPreview';
import ImagePreview from '../../../components/ImagePreview';
import { LinkStyle } from '../../../declarations/models/LinkStyle';
import { isM24MediaModel } from '../../../utils/typeChecks';

export const BannerBlockPreview: BlockPreviewComponent = () => {
  const { block, blockIndex } = useCurrentBlock();
  const currentBlock = block as BannerBlock;

  const textClasses = `${currentBlock?.textlineColor} ${currentBlock?.useTextbackgound ? 'text-background-lines' : ''}`;
  const hasTextbackground = `${currentBlock?.textlineColor} ${
    currentBlock?.useModuleContentBackgound ? 'has-textbackground' : 'no-textbackground'
  }`;

  const transparentClass = `${currentBlock?.isTransparent ? 'transparent' : ''} `;
  const moduleBodyClass = `module__body ${textClasses}`;
  const titleClass = `module__heading ${textClasses} t-${currentBlock?.titleTransform} h-${currentBlock?.titleSize} f-${currentBlock?.titleWeight}`;
  const contentLayoutClass = `content__layout ${currentBlock?.useModuleContentBackgound ? 'text-background' : ''}`;

  const convertToPercent = (v: number, max: number) => {
    return Number.parseFloat(((v as number) / (max || 100)).toFixed(2));
  };

  const alphaStrength = convertToPercent((currentBlock?.mediaobject?.alpha__strength as unknown as number) || 0, 100);
  const vignetteSize = currentBlock.mediaobject?.vignette__size as unknown as number;
  const vignetteStrength = convertToPercent(
    (currentBlock.mediaobject?.vignette__strength as unknown as number) || 0,
    100,
  );
  const gradientSize = currentBlock.mediaobject?.gradient__size as unknown as number;
  const gradientStrength = convertToPercent(
    (currentBlock.mediaobject?.gradient__strength as unknown as number) || 0,
    100,
  );

  let target = '_self';
  if (currentBlock?.items[0]?.type === 'link') {
    if (currentBlock?.items[0]?.target === LinkTarget.STANDARD) {
      target = '_blank';
    } else {
      target = currentBlock?.items[0]?.target || '_blank';
    }
  }

  return (
    <div className='blocktype--frontpage__module'>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <div
        className={`linkdiv ${currentBlock?.linkstyle === LinkStyle.CLICKABLE_MODULE ? 'module--link' : ''} `}
        data-target={target || '_self'}>
        <div
          className={`module module--banner module--${currentBlock?.size} ${currentBlock?.cssClass} layout--${
            currentBlock?.layout || 'img'
          } align--${currentBlock?.textAlign || 'default'} valign--${currentBlock?.textValign || 'default'} ${
            currentBlock?.skin || 'skin'
          } skin-banner ${currentBlock?.mediaobject?.id ? 'has-media' : 'no-media'} module--${
            currentBlock?.size || ''
          } ${currentBlock?.linkstyle === LinkStyle.CLICKABLE_MODULE ? 'module--link' : ''} ${hasTextbackground}`}>
          <div
            className={`module__grid ${currentBlock?.skin || 'skin'} skin-banner ${
              currentBlock?.mediaobject?.id ? 'has-media' : 'no-media'
            }  ${transparentClass}`}>
            {currentBlock?.mediaobject?.alpha_on && (
              <style>{`#block_${blockIndex}_media_${currentBlock?.mediaobject?.id}:after {
                  background-color: rgba(0,0,0,${alphaStrength});}`}</style>
            )}

            {currentBlock?.mediaobject?.vignette_on && vignetteSize < 0 && (
              <style>{`#block_${blockIndex}_media_${currentBlock.mediaobject?.id}:after {
            background-image: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0, ${vignetteStrength}) 0%, rgba(0,0,0, ${
                (vignetteStrength as unknown as number) / 2
              }) ${100 + vignetteSize}%, rgba(0,0,0, 0) 100%);}`}</style>
            )}

            {currentBlock?.mediaobject?.vignette_on && vignetteSize > 0 && (
              <style>{`#block_${blockIndex}_media_${currentBlock.mediaobject?.id}:after {
            background-image: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%, rgba(0,0,0, ${
              (vignetteStrength as unknown as number) / 2
            }) ${100 - vignetteSize}%, rgba(0,0,0, ${vignetteStrength}) 100%);}`}</style>
            )}

            {currentBlock?.mediaobject?.gradient_on && gradientSize < 0 && (
              <style>
                {`#block_${blockIndex}_media_${
                  currentBlock.mediaobject?.id
                }:after { background-image: -webkit-linear-gradient(top, rgba(0,0,0, 0) 0%, rgba(0,0,0, ${
                  (gradientStrength as unknown as number) / 2
                }) ${100 + gradientSize}%, rgba(0,0,0, ${gradientStrength}) 100%);
              background-image: linear-gradient(to top, rgba(0,0,0, 0) 0%, rgba(0,0,0, ${
                (gradientStrength as unknown as number) / 2
              }) ${100 + gradientSize}%, rgba(0,0,0, ${gradientStrength}) 100%);`}
              </style>
            )}

            {currentBlock?.mediaobject?.gradient_on && gradientSize > 0 && (
              <style>{`#block_${blockIndex}_media_${
                currentBlock.mediaobject?.id
              }:after { background-image: -webkit-linear-gradient(top, rgba(0,0,0, 0) 0%, rgba(0,0,0, ${
                (gradientStrength as unknown as number) / 2
              }) ${100 - gradientSize}%, rgba(0,0,0, ${gradientStrength}) 100%);`}</style>
            )}

            {currentBlock?.mediaobject?.id ? (
              <ImagePreview
                mediaObject={currentBlock?.mediaobject}
                id={`block_${blockIndex}_media_${currentBlock?.mediaobject?.id}`}
              />
            ) : (
              <div id={`block_${blockIndex}_media_${currentBlock?.mediaobject?.id}`} className='module__media' />
            )}
            {currentBlock?.mediaCredits && (
              <div className='banner__overlaycontainer'>
                <div className='banner__credits'>{currentBlock?.mediaCredits}</div>
              </div>
            )}
            <div className='module__content'>
              <div className={contentLayoutClass}>
                <header className='module__head'>
                  {currentBlock?.icon && <i className={`module__icon i-icon-${currentBlock?.icon}`} />}
                  {currentBlock?.insertTitle && <div className='banner__insert'>{currentBlock?.insertTitle}</div>}
                  {!currentBlock?.hidetitle && currentBlock?.title && (
                    <span className='banner__title'>
                      <span className='modify--size'>
                        <h3 className={titleClass}>{currentBlock?.title}</h3>
                      </span>
                    </span>
                  )}
                </header>
                <div className={moduleBodyClass}>
                  <div
                    dangerouslySetInnerHTML={{ __html: currentBlock?.bodytext || '' }} // eslint-disable-line react/no-danger
                  />
                  {currentBlock?.audio?.id && (
                    /* eslint-disable-next-line jsx-a11y/media-has-caption */
                    <audio controls>
                      <source src={currentBlock?.audio?.play_urls?.mp3} type='audio/mp3' />
                    </audio>
                  )}
                </div>
                <footer className='module__foot'>
                  {currentBlock?.linkstyle !== LinkStyle.CLICKABLE_MODULE && (
                    <LinksPreview
                      linkItems={currentBlock?.items}
                      linkStyle={currentBlock?.linkstyle}
                      overrideListClass='module__links'
                    />
                  )}
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerBlockPreview;
