import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { urlSafeString } from '@/utils/url';
import M24PageFinder from '@/components/Finder/M24PageFinder/M24PageFinder';
import { GenericMedia } from '@/declarations/GenericMedia';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import DateTimeInput from '@/components/forms/DateTimeInput';
import { deepCopy } from '@/utils/object';
import { Location } from '@/declarations/models/Location';
import SelectInput from '@/components/forms/SelectInput';
import { useStore } from '@/components/store/Store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TextInput from '../../components/forms/TextInput';
import CheckboxInput from '../../components/forms/CheckboxInput';
import SectionsController from '../../components/Section/SectionsController';
import Section from '../../components/Section/Section';
import Container from '../../components/Container';
import { useEditorModel } from '../lib/components/EditorDataProvider';
import { Page } from '../../declarations/models/Page';
import { Language } from '../../declarations/models/Language';
import Styles from '../../assets/js/Styles';
import SwitchInput from '../../components/forms/SwitchInput';
import { Api } from '../../services/Api';
import Loader from '../../components/Loader';
import { formatAPITimestamp } from '../../utils/dates';
import { PageVersion } from '../../declarations/models/PageVersion';
import { PageRevision } from '../../declarations/models/PageRevision';
import { ChildrenProp } from '../../declarations/ChildrenProp';
import PageEditorSaveButton from '../lib/components/PageEditorSaveButton';
import CategoriesInfo from '../../components/CategoriesInfo';
import { usePageEditorFormState } from './PageEditorFormStateProvider';
import { useEditorSubmitHandler } from '../lib/BaseEditor';
import { MainCategory } from '../../declarations/Category';

export interface PageSettingsProps {
  open: boolean;
  onClose: () => void;
}

export const PageSettings: FC<PageSettingsProps & ChildrenProp> = ({ open, onClose }) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { state } = useStore();
  const siteId = state.selectedSite?.id;
  const protectedStatus = useWatch<Page>({ name: `is_protected`, defaultValue: false });
  const page = useEditorModel<Page>();
  const isTemplate = page.is_template;
  const [revisions, setRevisions] = useState<Array<PageRevision>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editPathEnabled, setEditPathEnabled] = useState<boolean>(false);
  const originalPath = useRef(page?.path);
  const [templatePageFinderOpen, setTemplatePageFinderOpen] = useState(false);
  const [locations, setLocations] = useState<Array<Location>>();
  const { reset } = useFormContext<Page>();
  const confirm = useConfirmDialog();
  const { setInitialFormData } = usePageEditorFormState();
  const { setValue } = useFormContext();
  const submitForm = useEditorSubmitHandler();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const selectedLocation = useWatch<Page, 'location'>({ name: 'location' });
  const [availableLanguages, setAvailableLanguages] = useState<Array<Language>>();
  const selectedSiteLanguageLocale = state.selectedSiteLanguage;
  const mainLanguageLocale = state.selectedSite?.default_language;
  const [languageLinksPageFinderOpen, setLanguageLinksPageFinderOpen] = useState(false);
  const [languageLinksFinderLanguage, setLanguageLinksFinderLanguage] = useState('');
  useEffect(() => {
    if (siteId) {
      const ctx = Api.getSiteLocations(siteId);
      ctx
        .fetchDirect(null)
        .then((l) => !!l && setLocations(l))
        .finally(ctx.abort);
    }
  }, [siteId]);

  useEffect(() => {
    if (state.selectedSite?.site_contents) {
      setAvailableLanguages(state.selectedSite.site_contents);
    } else {
      setAvailableLanguages([]);
    }
  }, [state.selectedSite?.site_contents]);

  const {
    field: { onChange: setPagePath },
  } = useController<Page, 'path'>({
    name: 'path',
  });

  const getVersionNumber = (version_id?: number) => {
    if (version_id) {
      const pageVersion = page.versions?.find((v) => v.id === version_id);
      return pageVersion?.version_number || '?';
    }
    return '?';
  };
  type versionPath = `version[0}].${string}`;
  const getVersionIndex = (version: PageVersion): number => {
    return page.versions?.findIndex((v) => v.id === version.id) || 0;
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    let unmounted = false;
    setIsLoading(true);

    const ctx = Api.getPageRevisions(page?.id || 0, 0, 10, page?.active_version_id || 0);
    ctx
      .fetchDirect(null)
      .then((fetchedRevisions) => {
        if (!unmounted && fetchedRevisions) {
          setRevisions(fetchedRevisions);
        }
      })
      .finally(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      });
    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [open, page?.active_version_id, page?.id]);

  const onTemplateSelected = async (selected: Array<GenericMedia<Page>>) => {
    const [selectedTemplate] = selected;
    if (!selectedTemplate.source?.id) {
      console.warn('no template selected');
      return;
    }
    const template = await Api.getPageTemplate(selectedTemplate.source.id!).fetchDirect(null);
    if (!template?.content) {
      console.warn('template missing content');
      return;
    }

    if (!(await confirm(tComp('PageSettings.ConfirmApplyTemplate')))) {
      return;
    }

    if (template.insert_title) {
      page.insert_title = template.insert_title;
    }

    const content = deepCopy(template.content);
    const p = {
      ...page,
      content,
    };
    reset(p);
    // setInitialFormData(page);
    // eslint-disable-next-line no-restricted-syntax
    // for (const [name, value] of Object.entries(p || {})) {
    // setValue(name, value); // Don't use reset(), it causes input fields getting incorrect data from the form's defaultValues after reordering
    // }

    onClose();
  };

  const {
    field: { value: languageLinks, onChange: setLanguageLinks },
  } = useController<Page, 'language_links'>({ name: 'language_links' });

  const onLanguageLinksSelected = async (selected: Array<GenericMedia<Page>>) => {
    const [selectedPage] = selected;
    console.log(selectedPage.source);
    const newLanguageLinks = {
      ...languageLinks,
      [selectedPage.source?.locale]: selectedPage.source,
    };
    setLanguageLinks(newLanguageLinks);
  };

  const onDeleteLanugageLink = (locale: string) => {
    const newLanguageLinks = {
      ...languageLinks,
      [locale]: {},
    };
    setLanguageLinks(newLanguageLinks);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Fade in={open}>
        <Container p={4} fullWidth fullHeight top>
          <Paper
            sx={{
              backgroundColor: Styles.Colors.THEME_BG_COLOR,
              width: '100%',
              position: 'relative',
              overflow: 'auto',
              maxHeight: '94vh',
            }}>
            <Container fullWidth right px={2} py={2}>
              <PageEditorSaveButton options={[]} />
              <Button
                variant='contained'
                color='secondary'
                startIcon={<MaterialSymbol name='close' />}
                onClick={onClose}>
                {tComp('PageSettings.Close')}
              </Button>
            </Container>
            <SectionsController px={2} defaultExpanded={['general']}>
              {/* TODO: Set season */}
              {/* TODO: Set DiMu ID */}
              {/* TODO: Toggle is-search-result-page */}
              <Section sectionId='general' title={tComp('PageSettings.Sections.general')}>
                <Container gap={2} column top left fullWidth>
                  <TextInput<Page> path='title' label={tComp('PageSettings.FormLabels.Title')} required />
                  <TextInput<Page>
                    key={`path-${page?.id}-${editPathEnabled ? 'enabled' : 'disabled'}`}
                    path='path'
                    label={tComp('PageSettings.FormLabels.Slug')}
                    required
                    disabled={!editPathEnabled}
                    inputFormatter={(val) => urlSafeString(val, '/')}
                  />
                  {!editPathEnabled && (
                    <Button onClick={() => setEditPathEnabled(true)}>{tComp('PageSettings.EnableEditPath')}</Button>
                  )}
                  {editPathEnabled && (
                    <Button
                      onClick={() => {
                        setPagePath(originalPath.current);
                        setEditPathEnabled(false);
                      }}>
                      {tComp('PageSettings.CancelEditPath', { old: originalPath.current })}
                    </Button>
                  )}
                  <Typography>
                    <b>{tComp('PageSettings.Category')}: </b>
                    {tCommon(`MainCategory.${page.main_category ?? ''}`)}
                  </Typography>
                  {page.category?.tag && (
                    <>
                      <Typography>
                        <b>{tComp('PageSettings.CategoryOld')}: </b>
                        {page.category?.tag ?? ''}
                      </Typography>
                    </>
                  )}
                  <CategoriesInfo />

                  <CheckboxInput<Page> path='content.isLongread' label={tComp('PageSettings.FormLabels.IsLongread')} />
                  {page.content?.isLongread && (
                    <>
                      <TextInput<Page>
                        path='content.longreadsettings.logotext'
                        label={tComp('PageSettings.LongreadLogoText')}
                      />
                      <TextInput<Page>
                        path='content.longreadsettings.logolink'
                        label={tComp('PageSettings.LongreadLogoLink')}
                      />
                    </>
                  )}

                  <Button
                    variant='contained'
                    color='secondary'
                    sx={{ flex: '1 0 auto', mt: 4 }}
                    onClick={() => setTemplatePageFinderOpen(true)}>
                    {tComp('PageSettings.SelectTemplateButton')}
                  </Button>
                  <M24PageFinder
                    open={templatePageFinderOpen}
                    onClose={() => setTemplatePageFinderOpen(false)}
                    onSelectionConfirmed={onTemplateSelected}
                    type='template'
                    multiSelect={false}
                  />

                  <Alert severity='info' sx={{ width: '100%' }}>
                    {tComp('PageSettings.SelectTemplateDescription')}
                  </Alert>

                  <Box
                    sx={{
                      mt: 4,
                      minWidth: '265px',
                    }}>
                    {/* TODO: Choose location */}
                    <Container fullWidth column gap={2}>
                      {locations && (
                        <SelectInput
                          // hack(MV-737): 'location' is (only sometimes...) reset to the original when it gets removed
                          //  solution: key the component with the selected location.
                          //  unfortunately this breaks focus when selection changes, so keyboard users will get mad
                          //  todo: find a proper solution
                          key={selectedLocation?.id ?? 0}
                          options={locations}
                          getOptionKey={(l) => (l ? `${l.id},${l.id}` : '')}
                          getOptionLabel={(l) => String(l?.title)}
                          path='location'
                          label={tComp('PageSettings.Locations')}
                          disabled={!locations.length}
                          defaultValue=''
                          size='small'
                        />
                      )}
                    </Container>
                  </Box>
                  <Box
                    sx={{
                      mt: 4,
                    }}>
                    <DateTimeInput path='published_at' label='Publiseringsdato' inputType='date' />
                  </Box>
                </Container>
              </Section>
              <Section sectionId='languageConnections' title={tComp('PageSettings.Sections.languageConnections')}>
                {page.locale === mainLanguageLocale && (
                  <Typography maxWidth={1000}>{tComp('PageSettings.LanguageConnections.Intro')}</Typography>
                )}
                {page.locale !== mainLanguageLocale && (
                  <Typography maxWidth={1000}>
                    {tComp('PageSettings.LanguageConnections.IntroNotMainLanguage')}
                  </Typography>
                )}

                <TableContainer
                  component={Paper}
                  style={{
                    border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                    borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                    marginTop: '20px',
                  }}>
                  <Table size='medium'>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>{tComp('PageSettings.LanguageConnections.OtherLanguages')}</TableCell>
                        <TableCell colSpan={2}> {tComp('PageSettings.LanguageConnections.PageConnections')}</TableCell>
                      </TableRow>
                    </TableHead>
                    {availableLanguages
                      ?.filter((language) => language.status !== 'draft' && language.locale !== page.locale)
                      .map((language) => (
                        <TableRow
                          key={language.locale}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}>
                          <TableCell width={250} sx={{ fontWeight: 500 }}>
                            {tCommon(`Languages.${language.locale}`)}
                            {language.locale === mainLanguageLocale && (
                              <strong> ({tComp('PageSettings.LanguageConnections.MainLanguage')})</strong>
                            )}
                          </TableCell>
                          <TableCell width={200}>
                            {page.locale !== language.locale && page.locale === mainLanguageLocale && (
                              <Button
                                variant='contained'
                                color='secondary'
                                disabled={language.locale === page.locale || page.locale !== mainLanguageLocale}
                                onClick={() => {
                                  setLanguageLinksFinderLanguage(language.locale);
                                  setLanguageLinksPageFinderOpen(true);
                                }}>
                                {tComp('PageSettings.LanguageConnections.ConnectPage')}
                              </Button>
                            )}
                          </TableCell>
                          <TableCell width={300}>
                            {(page?.language_links &&
                              page.language_links[language.locale] &&
                              page.language_links[language.locale].title) ||
                              tComp('PageSettings.LanguageConnections.NoConnection')}
                          </TableCell>
                          <TableCell>
                            {page.locale === mainLanguageLocale &&
                              page?.language_links &&
                              page.language_links[language.locale] &&
                              page.language_links[language.locale].title && (
                                <Button
                                  variant='contained'
                                  color='secondary'
                                  disabled={page.locale !== mainLanguageLocale}
                                  onClick={() => {
                                    onDeleteLanugageLink(language.locale);
                                  }}>
                                  {tComp('PageSettings.LanguageConnections.RemoveConnection')}
                                </Button>
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                </TableContainer>

                <M24PageFinder
                  open={languageLinksPageFinderOpen}
                  onClose={() => setLanguageLinksPageFinderOpen(false)}
                  onSelectionConfirmed={onLanguageLinksSelected}
                  type='page'
                  isEvent={page.main_category === MainCategory.EVENT}
                  multiSelect={false}
                  lockedLanguage={languageLinksFinderLanguage}
                />
              </Section>
              <Section sectionId='revisions' title={tComp('PageSettings.Sections.revisions')}>
                {isLoading ? (
                  <Loader size={25} />
                ) : (
                  <>
                    <Typography maxWidth={1000}>{tComp('PageSettings.RevisionsTable.InfoText')}</Typography>
                    <TableContainer
                      component={Paper}
                      style={{
                        border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                        marginTop: '20px',
                      }}>
                      <Table sx={{ minWidth: 650 }} size='medium'>
                        <TableHead sx={{ backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY }}>
                          <TableRow>
                            <TableCell>{tComp('PageSettings.RevisionsTable.SavedTimestamp')}</TableCell>
                            <TableCell>{tComp('PageSettings.RevisionsTable.CreatedBy')}</TableCell>
                            <TableCell>{tComp('PageSettings.RevisionsTable.RevisionID')}</TableCell>
                            <TableCell>{tComp('PageSettings.RevisionsTable.Version')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {revisions?.map((revision) => (
                            <TableRow key={revision.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component='th' scope='row'>
                                {formatAPITimestamp(revision.archived_at, 'datetime')}
                              </TableCell>
                              <TableCell>UserID: {revision.created_by_id}</TableCell>
                              <TableCell>{revision.id}</TableCell>
                              <TableCell>{getVersionNumber(revision?.page_version_id)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Section>
              <Section sectionId='versions' title={tComp('PageSettings.Sections.versions')}>
                <Typography maxWidth={1000}>{tComp('PageSettings.VersionsTable.InfoText')}</Typography>
                <TableContainer
                  component={Paper}
                  style={{
                    border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                    borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                    marginTop: '20px',
                  }}>
                  <Table sx={{ minWidth: 650 }} size='medium'>
                    <TableHead sx={{ backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY }}>
                      <TableRow>
                        <TableCell>{tComp('PageSettings.VersionsTable.VersionID')}</TableCell>
                        <TableCell>{tComp('PageSettings.VersionsTable.OptVersionTitle')}</TableCell>
                        <TableCell>{tComp('PageSettings.VersionsTable.Choosen')}</TableCell>
                        <TableCell>{tComp('PageSettings.VersionsTable.VersionStatus')}</TableCell>
                        <TableCell>{tComp('PageSettings.VersionsTable.PageStatus')}</TableCell>
                        <TableCell>{tComp('PageSettings.VersionsTable.Created')}</TableCell>
                        <TableCell>{tComp('PageSettings.VersionsTable.CreatedBy')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {page.versions?.map((version, index) => (
                        <TableRow key={version.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component='th' scope='row'>
                            {version.version_number} : {version.id}
                          </TableCell>
                          <TableCell>{version.title}</TableCell>
                          <TableCell>
                            {version.id === page.active_version_id
                              ? tComp('PageSettings.VersionsTable.Yes')
                              : tComp('PageSettings.VersionsTable.No')}
                          </TableCell>
                          <TableCell>{tCommon(`Status.${version.status}`)}</TableCell>
                          <TableCell>{tCommon(`Status.${page.status}`)}</TableCell>
                          <TableCell>{formatAPITimestamp(version.created_at, 'datetime')}</TableCell>
                          <TableCell>UserID: {version.created_by_id}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Section>
              <Section sectionId='security' title={tComp('PageSettings.Sections.security')}>
                <Container gap={2} column top left fullWidth>
                  <SwitchInput<Page> path='is_protected' label={tComp('PageSettings.FormLabels.isProtected')} />
                  <TextInput<Page>
                    path='access_code'
                    label={tComp('PageSettings.FormLabels.accessCode')}
                    disabled={!protectedStatus}
                    defaultValue=''
                  />
                  <TextInput<Page>
                    path='content.protectedIntro'
                    label={tComp('PageSettings.FormLabels.protectedIntro')}
                    disabled={!protectedStatus}
                    defaultValue=''
                  />
                  <TextInput<Page>
                    path='content.protectedError'
                    label={tComp('PageSettings.FormLabels.protectedError')}
                    disabled={!protectedStatus}
                    defaultValue=''
                  />
                  <TextInput<Page>
                    path='content.protectedLabel'
                    label={tComp('PageSettings.FormLabels.protectedLabel')}
                    disabled={!protectedStatus}
                    defaultValue=''
                  />
                  <TextInput<Page>
                    path='content.protectedButton'
                    label={tComp('PageSettings.FormLabels.protectedButton')}
                    disabled={!protectedStatus}
                    defaultValue=''
                  />
                </Container>
              </Section>
            </SectionsController>
            <Container fullWidth right px={2} py={2}>
              <PageEditorSaveButton options={[]} />
              <Button
                variant='contained'
                color='secondary'
                startIcon={<MaterialSymbol name='close' />}
                onClick={onClose}>
                {tComp(`PageSettings.${isTemplate ? 'CloseTemplateSettings' : 'Close'}`)}
              </Button>
            </Container>
          </Paper>
        </Container>
      </Fade>
    </Modal>
  );
};

export default PageSettings;
