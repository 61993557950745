import React, { FC, useState } from 'react';
import { ButtonBase, Collapse, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useStore } from '../../../components/store/Store';
import Container from '../../../components/Container';
import { M24MediaModel } from '../../../declarations/models/M24MediaModel';
import { Language } from '../../../declarations/models/Language';

export interface EditMediaTextFieldsProps {
  item?: M24MediaModel;
  setTitle: (title: string, locale?: string) => void;
  setCaption: (title: string, locale?: string) => void;
  setAltText: (title: string, locale?: string) => void;
  locales?: Array<Language>;
}

export const inputGroupStyling = {
  padding: 2,
  gap: '16px',
  width: '100%',
  backgroundColor: 'white',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
};

export const EditMediaTextFields: FC<EditMediaTextFieldsProps> = ({
  item,
  setTitle,
  setCaption,
  setAltText,
  locales,
}) => {
  const { t } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { state } = useStore();
  const defaultLanguage = state.selectedSite?.default_language;
  const otherLocales = locales?.filter((l) => l.locale !== defaultLanguage);
  const [localesOpen, setLocalesOpen] = useState<boolean>(false);
  const [selectedLocale, setSelectedLocale] = useState<Language | undefined>(otherLocales?.[0]);

  const titleLabel = t('MediaEditor.Title');
  const altTextLabel = t('MediaEditor.AltText');
  let captionLabel = t('MediaEditor.Caption');

  if (item?.type === 'image') {
    captionLabel = t('MediaEditor.ImageCaption');
  }
  return (
    <Container left sx={inputGroupStyling} column>
      <Typography variant='h6'>
        {t('MediaEditor.TextFieldsTitle')} ({tCommon(`Languages.${defaultLanguage}`)})
      </Typography>
      <Container fullWidth column left gap={2}>
        <TextField
          size='small'
          label={`${titleLabel}`}
          fullWidth
          value={item?.title ?? ''}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          size='small'
          label={`${captionLabel}`}
          fullWidth
          value={item?.content?.caption ?? ''}
          onChange={(e) => setCaption(e.target.value)}
        />
        <TextField
          size='small'
          label={`${altTextLabel}`}
          fullWidth
          value={item?.content?.alttext ?? ''}
          onChange={(e) => setAltText(e.target.value)}
        />
        {!!otherLocales?.length && selectedLocale && (
          <Container fullWidth column gap={0}>
            <Container
              fullWidth
              spaceBetween
              component={ButtonBase}
              onClick={() => setLocalesOpen(!localesOpen)}
              p='4px'>
              <Typography variant='subtitle2'>
                {localesOpen ? t('MediaEditor.HideEditLocales') : t('MediaEditor.ShowEditLocales')}
              </Typography>
              {localesOpen ? <MaterialSymbol name='expand_less' /> : <MaterialSymbol name='expand_more' />}
            </Container>
            <Collapse in={localesOpen} sx={{ width: '100%' }}>
              <Container column fullWidth>
                <ToggleButtonGroup
                  size='small'
                  value={selectedLocale}
                  exclusive
                  onChange={(e, v) => !!v && setSelectedLocale(v)}>
                  {otherLocales.map((l) => (
                    <ToggleButton key={l.locale} value={l}>
                      {tCommon(`Languages.${l.locale}`)}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <Container fullWidth column left gap={2}>
                  <TextField
                    size='small'
                    fullWidth
                    label={`${titleLabel} (${selectedLocale.locale})`}
                    value={item?.content?.[selectedLocale.locale]?.title ?? ''}
                    onChange={(e) => setTitle(e.target.value, selectedLocale.locale)}
                  />
                  <TextField
                    size='small'
                    fullWidth
                    label={`${captionLabel} (${selectedLocale.locale})`}
                    value={item?.content?.[selectedLocale.locale]?.caption ?? ''}
                    onChange={(e) => setCaption(e.target.value, selectedLocale.locale)}
                  />
                  <TextField
                    size='small'
                    fullWidth
                    label={`${altTextLabel} (${selectedLocale.locale})`}
                    value={item?.content?.[selectedLocale.locale]?.alttext ?? ''}
                    onChange={(e) => setAltText(e.target.value, selectedLocale.locale)}
                  />
                </Container>
              </Container>
            </Collapse>
          </Container>
        )}
      </Container>
    </Container>
  );
};

export default EditMediaTextFields;
