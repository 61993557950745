import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import SelectInput from '@/components/forms/SelectInput';
import { Divider, FormControlLabel, IconButton, Radio, Tooltip, Typography } from '@mui/material';
import i18next from 'i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../../components/Container';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import FinderButton, { FinderType } from '../../../../components/Finder/FinderButton';
import { GenericMedia } from '../../../../declarations/GenericMedia';
import { settingsAreaStyle } from '../EmployeeBlocks/EmployeesAutoBlock';
import KPBlockItem from './KPBlockItem';
import { Api } from '../../../../services/Api';
import SwitchInput from '../../../../components/forms/SwitchInput';
import { KPBlockModel, KPViewType, PresDisplayType } from '../../../../declarations/models/blocks/KPBlock';
import { KPObject, KPProcessedFields } from '../../../../declarations/models/KP';
import Image from '../../../../components/Image';
import { resolveLocalizedString } from '../../../../utils/strings';
import Styles from '../../../../assets/js/Styles';
import { noWrapTextStyle } from '../../../../views/MediaView/MediaViewTypes/ListViewItem';
import KPLogo from '../../../../components/icons/KPLogo';
import { KPSchemaId } from '../../../../declarations/KPSchemaId';
import CheckboxInput from '../../../../components/forms/CheckboxInput';

const presStyling = {
  backgroundColor: Styles.Colors.KP_PRES,
  border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
  position: 'relative',
  overflow: 'hidden',
  maxWidth: '100%',
  p: 2,
  gap: 1,
};

export const KPBlock: BlockComponent = () => {
  const { t } = useTranslation('components');
  const currentLang = i18next.language;
  const { blockPath, block } = useCurrentBlock();
  const currentBlock = block as KPBlockModel;

  const gridSizeOptions = ['auto', '3', '4', '5', '6'];

  const [presObject, setPresObject] = useState<KPObject>();

  const {
    field: { value: presentation, onChange: setPresentation },
  } = useController<BlockSpecificPage<KPBlockModel>, `${typeof blockPath}.presentation`>({
    name: `${blockPath}.presentation`,
  });

  const {
    fields: kpObjects,
    append: appendKp,
    insert: insertKp,
    move: moveKp,
    remove: removeKp,
  } = useControlledFieldArray<BlockSpecificPage<KPBlockModel>, `${BlockPath}.articles`, 'itemHash'>({
    name: `${blockPath}.articles`,
  });

  const {
    fields: presRefs,
    append: appendPresRef,
    insert: insertPresRef,
    move: movePresRef,
    remove: removePresRef,
  } = useControlledFieldArray<BlockSpecificPage<KPBlockModel>, `${BlockPath}.presentation.articles`, 'itemHash'>({
    name: `${blockPath}.presentation.articles`,
  });

  useEffect(() => {
    if (presentation?.id) {
      const ctx = Api.getKPObjectFull(presentation.id);
      ctx
        .fetchDirect(null)
        .then((res) => !!res && setPresObject(res))
        .finally(ctx.abort);
    } else setPresObject(undefined);
  }, [presentation?.id]);

  const handleSetPres = (item: KPProcessedFields | null) => {
    if (item?.document_id) {
      setPresentation({
        id: item.document_id,
        articles: [],
        displayType: PresDisplayType.ALL_POINTS,
        selectionIndex: 0,
      });
    }
  };

  const handleAddKpObject = (item: KPProcessedFields | null) => {
    if (item?.document_id) appendKp({ id: item.document_id, title: item.title });
  };

  const handleChangePresentationViewType = (type: PresDisplayType) => {
    setPresentation({
      ...presentation,
      displayType: type,
    });
  };

  const handleChangeSelectionIndex = (i: number) => {
    setPresentation({
      ...presentation,
      selectionIndex: i,
    });
  };

  const handleAddPresRef = (r: KPProcessedFields | null) => {
    if (r?.document_id) appendPresRef({ id: r.document_id });
  };

  const handleRemovePres = () => {
    setPresentation({
      ...presentation,
      id: undefined,
      articles: [],
      selectionIndex: 0,
    });
  };

  const imgSrc = presObject?.media_data?.image_url || presObject?.image_url;
  return (
    <Container gap={2} column top left fullWidth>
      <Container column gap={2} fullWidth>
        <FinderButton
          type={FinderType.KP}
          overrideText={t('KPBlock.KPPresentationLabel')}
          multiSelect={false}
          finderProps={{
            onSelectionConfirmed: (items: Array<GenericMedia<KPProcessedFields>>) =>
              !!items.length && handleSetPres(items[0].source),
            schemaIds: [KPSchemaId.KP_PRESENTATION],
          }}
        />
        {!!presObject && (
          <Container column fullWidth sx={presStyling}>
            <Container left fullWidth top>
              <Container top sx={{ width: '128px' }}>
                {imgSrc ? (
                  <Image
                    src={imgSrc}
                    alt={resolveLocalizedString(currentLang, presObject?.description)}
                    loading='lazy'
                    maxHeight='128px'
                    maxWidth='128px'
                    style={{ borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT }}
                  />
                ) : (
                  <KPLogo
                    sx={{
                      width: '128px',
                      height: '128px',
                      padding: '48px 48px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      backgroundColor: Styles.Colors.MEDIUM_LIGHT_GREY,
                      borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                    }}
                  />
                )}
              </Container>
              <Container column left gap={0} sx={{ width: 'calc(100% - 136px)' }}>
                <Container fullWidth spaceBetween>
                  <Typography sx={{ ...noWrapTextStyle, maxWidth: 'calc(100% - 48px)' }}>
                    {resolveLocalizedString(currentLang, presObject?.title)}
                  </Typography>
                  <Tooltip title={t('DMBlock.RemoveObject')} arrow color='error'>
                    <IconButton size='small' onClick={handleRemovePres}>
                      <MaterialSymbol name='do_not_disturb_on' />
                    </IconButton>
                  </Tooltip>
                </Container>
                <Container column left gap={0} fullWidth>
                  <Typography
                    variant='caption'
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                    }}>
                    {resolveLocalizedString(currentLang, presObject.description)}
                  </Typography>
                </Container>
              </Container>
            </Container>
            <Container fullWidth column left>
              <Container left fullWidth wrap gap={0}>
                <FormControlLabel
                  checked={presentation?.displayType === PresDisplayType.ALL_POINTS}
                  onChange={() => handleChangePresentationViewType(PresDisplayType.ALL_POINTS)}
                  control={<Radio />}
                  label={t('KPBlock.ListArticlesLabel')}
                />
                <FormControlLabel
                  checked={presentation?.displayType === PresDisplayType.PICK_POINTS}
                  onChange={() => handleChangePresentationViewType(PresDisplayType.PICK_POINTS)}
                  control={<Radio />}
                  label={t('KPBlock.PickSubArticlesLabel')}
                />
                <FormControlLabel
                  checked={presentation?.displayType === PresDisplayType.SELECTION}
                  onChange={() => handleChangePresentationViewType(PresDisplayType.SELECTION)}
                  control={<Radio />}
                  label={t('KPBlock.PickSelectionLabel')}
                />
              </Container>
              {presentation?.displayType === PresDisplayType.PICK_POINTS && (
                <>
                  <Divider flexItem sx={{ marginBottom: 1 }} />
                  <FinderButton
                    type={FinderType.KP}
                    overrideText={t('KPBlock.PresChildrenLabel')}
                    sx={{ height: '32px' }}
                    finderProps={{
                      onSelectionConfirmed: (items: Array<GenericMedia<KPProcessedFields>>) =>
                        items.forEach((item) => handleAddPresRef(item.source)),
                      schemaIds: [KPSchemaId.KP_ARTICLE],
                      referencedFromId: presentation.id,
                    }}
                  />
                  <Container column fullWidth gap={0} mb={-1}>
                    {presRefs?.map((item, i) => (
                      <KPBlockItem
                        key={item.id}
                        index={i}
                        onReorder={movePresRef}
                        onAdd={(itemToAdd, addAtIndex) => itemToAdd && insertPresRef(addAtIndex, itemToAdd)}
                        onRemove={removePresRef}
                        itemPathPrefix={`${blockPath}.presentation.articles`}
                      />
                    ))}
                  </Container>
                </>
              )}
              {presentation?.displayType === PresDisplayType.SELECTION && (
                <Container column gap={0} left fullWidth>
                  <Divider flexItem sx={{ marginBottom: 1 }} />
                  {presObject.content?.selections?.map((r, i) => (
                    <FormControlLabel
                      key={r.internalTitle}
                      checked={presentation?.selectionIndex === i}
                      onChange={() => handleChangeSelectionIndex(i)}
                      control={<Radio />}
                      label={r.internalTitle}
                    />
                  ))}
                </Container>
              )}
            </Container>
          </Container>
        )}
      </Container>
      <Container column fullWidth>
        <Container fullWidth>
          <FinderButton
            type={FinderType.KP}
            overrideText={t('KPBlock.KPArticlesLabel')}
            finderProps={{
              onSelectionConfirmed: (items: Array<GenericMedia<KPProcessedFields>>) =>
                items.forEach((item) => handleAddKpObject(item.source)),
              schemaIds: [KPSchemaId.KP_ARTICLE],
            }}
          />
        </Container>

        {!!kpObjects.length && (
          <Container column fullWidth gap={0}>
            {kpObjects.map((item, i) => (
              <KPBlockItem
                key={item.id}
                index={i}
                onReorder={moveKp}
                onAdd={(itemToAdd, addAtIndex) => itemToAdd && insertKp(addAtIndex, itemToAdd)}
                onRemove={removeKp}
                itemPathPrefix={`${blockPath}.articles`}
              />
            ))}
          </Container>
        )}
      </Container>

      <Container fullWidth column left sx={settingsAreaStyle}>
        {currentBlock?.view !== KPViewType.MAP && (
          <>
            <CheckboxInput
              fullWidth={false}
              path={`${blockPath}.displayDescription`}
              label={t('KPBlock.displayDescription')}
            />
            <CheckboxInput
              fullWidth={false}
              path={`${blockPath}.whiteBackground`}
              label={t('KPBlock.whiteBackground')}
            />
          </>
        )}
        {currentBlock?.view === KPViewType.GRID && (
          <>
            <br />
            <SelectInput
              hideNoSelectionOption
              defaultValue='auto'
              options={gridSizeOptions}
              getOptionKey={(gridSize) => `size-${gridSize}`}
              getOptionLabel={(gridSize) => `${gridSize}`}
              path={`${blockPath}.gridSize`}
              label={t('CardModuleBlock.GridSizeLabel')}
            />
            <br />
          </>
        )}
        <Tooltip title={t('KPBlock.IntegratedViewLabel')} arrow>
          <div>
            <SwitchInput
              path={`${blockPath}.integratedView`}
              defaultValue={false}
              label={t('KPBlock.IntegratedView')}
            />
          </div>
        </Tooltip>
      </Container>
    </Container>
  );
};

export default KPBlock;
