import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/components/store/Store';
import Styles from '@/assets/js/Styles';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { Typography } from '@mui/material';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import Container from '../../../components/Container';
import SwitchInput from '../../../components/forms/SwitchInput';
import { useControlledFieldArray } from '../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../editor/lib/declarations/BlockSpecificPage';
import LinkListItem from '../../../components/Links/LinkListItem';
import { LinkType } from '../../../declarations/models/LinkType';
import { Draggable } from '../../../declarations/Draggable';
import TextInput from '../../../components/forms/TextInput';
import Links from '../../../components/Links/Links';
import { FooterMenuBlock } from '../../../declarations/models/blocks/settingsBlocks/FooterMenuBlock';
import LanguageFooterMenuSubMenuSettings from './LanguageFooterMenuSubMenuSettings';

export interface LanguageFooterMenuSettingsProps {
  blockPath: BlockPath;
}

export const LanguageFooterMenuSettings: FC<LanguageFooterMenuSettingsProps> = ({ blockPath }) => {
  const { t } = useTranslation('components');
  const { state } = useStore();
  const isSuperAdmin = state.currentUser?.user?.is_super_admin;
  const {
    fields: items,
    append,
    update,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<FooterMenuBlock>, `${BlockPath}.items`>({ name: `${blockPath}.items` });

  return (
    <Container fullWidth column top left gap={2}>
      {items?.map((item, i) => (
        <LinkListItem
          key={item.id ? item.id + i : (item.url || item.title || '') + i} // eslint-disable-line react/no-array-index-key
          linkType={item.type as LinkType}
          type={Draggable.OTHER}
          index={i}
          onReorder={move}
          onDelete={remove}
          itemsArrayName='items'
          showLinkDescriptionField={false}
          onChangeFile={() => null}
          blockPath={blockPath}
          iconOverride={'status' in item ? <PageStatusCircle status={item.status} size={20} /> : undefined}
          color={Styles.Colors.LIGHT_GREY}
          headerSubTitle={
            <Typography fontSize='small'>
              {t('Settings.LanguageSettings.childCount', { count: item.items?.length ?? 0 })}
            </Typography>
          }>
          <Container fullWidth p={2} column>
            <Container fullWidth>
              <TextInput
                path={`${blockPath}.items.${i}.title`}
                label={t('Settings.LanguageSettings.LanguageFooterSettings.menu.menuPointTitle')}
                defaultValue=''
              />
              {item.type === LinkType.LINK && (
                <TextInput
                  path={`${blockPath}.items.${i}.url`}
                  label={t('Settings.LanguageSettings.LanguageMenuSettings.url')}
                  defaultValue=''
                />
              )}
              <TextInput
                path={`${blockPath}.items.${i}.cssClass`}
                label={t('Settings.LanguageSettings.LanguageFooterSettings.menu.cssClass')}
                defaultValue=''
              />
            </Container>
            <LanguageFooterMenuSubMenuSettings itemPath={`${blockPath}.items.${i}`} />
          </Container>
        </LinkListItem>
      ))}
      <Links
        onUpdate={update}
        onAppend={append}
        onRemove={remove}
        onReorder={move}
        showLinkItems={false}
        showLinkDescriptionField={false}
        showLinkStyleSelector={false}
        showListChildrenInput={false}
        linkItems={items}
        linkTypes={[LinkType.PAGE, LinkType.LINK]}
        blockPath={blockPath}
      />

      {isSuperAdmin && (
        <div className='superadmin' style={{ backgroundColor: '#fbe6ff', padding: '1em', width: '100%' }}>
          <SwitchInput
            path={`${blockPath}.includeSearchField`}
            label={t('Settings.LanguageSettings.LanguageFooterSettings.menu.includeSearchField')}
            defaultValue={false}
          />
          <SwitchInput
            path={`${blockPath}.includeNavPath`}
            label={t('Settings.LanguageSettings.LanguageFooterSettings.menu.includeNavPath')}
            defaultValue={false}
          />
        </div>
      )}
    </Container>
  );
};

export default LanguageFooterMenuSettings;
