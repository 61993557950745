import React, { FC, ReactNode, useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { FinderHeader } from '@/components/Finder/FinderHeader';
import Container from '../../Container';
import DMLogo from '../../icons/DMLogo';
import LicenseSelector from '../../LicenseSelector';
import { MediaLicense } from '../../../declarations/models/MediaLicense';
import { useStore } from '../../store/Store';
import { Api } from '../../../services/Api';
import { DMObjectType } from '../../../declarations/models/DMObjectType';

export interface DMFinderHeaderProps {
  queryString: string;
  mediaLicense: MediaLicense | null;
  selectedOwnerCode: string;
  selectedObjectType: DMObjectType | null;
  onQueryChanged: (query: string) => void;
  onMediaLicenseChanged: (mediaLicense: MediaLicense | null) => void;
  onOwnerCodeChanged: (ownerCode: string) => void;
  onObjectTypeChanged: (objectType: DMObjectType | null) => void;
  objectTypeSelectable?: boolean;
  hasLocation: boolean;
  setHasLocation: (v: boolean) => void;
  hasPictures: boolean;
  setHasPictures: (v: boolean) => void;
}

export const DMFinderHeader: FC<DMFinderHeaderProps> = ({
  queryString,
  mediaLicense,
  selectedOwnerCode = '',
  selectedObjectType = null,
  onQueryChanged,
  onMediaLicenseChanged,
  onOwnerCodeChanged,
  onObjectTypeChanged,
  objectTypeSelectable = true,
  hasLocation,
  setHasLocation,
  hasPictures,
  setHasPictures,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const {
    state: { selectedSite },
  } = useStore();
  const m2State = useStore();

  const [isLoadingOwner, setIsLoadingOwner] = useState<boolean>(false);
  const [ownerCode, setOwnerCode] = useState<string | null>(null);
  const currentOwnerCodes = selectedSite?.content?.settings?.dmOwners;
  const [ownerCodes, setOwnerCodes] = useState<string | null>(currentOwnerCodes || null);

  useEffect(() => {
    let unmounted = false;
    const currentOwnerId = selectedSite?.owner_id || selectedSite?.owner?.id || 0;

    const ctx = Api.getOwnerById(currentOwnerId);
    setIsLoadingOwner(true);
    ctx
      .fetchDirect(null)
      .then((owner) => {
        if (!unmounted) {
          setOwnerCode(owner?.identifier || null);
          onOwnerCodeChanged(owner?.identifier || '');
        }
      })
      .finally(() => {
        if (!unmounted) setIsLoadingOwner(false);
      });

    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [selectedSite]);

  const handleOwnerCodeSwitch = (v: string) => {
    onOwnerCodeChanged(v ?? '');
  };

  return (
    <FinderHeader
      title={
        <>
          <DMLogo sx={(theme) => ({ height: theme.spacing(5), width: theme.spacing(10) })} />
          <Typography variant='h2' fontSize='18px' lineHeight='30px' fontWeight='700' letterSpacing='1px' pl={0} ml={0}>
            DigitaltMuseum
          </Typography>
        </>
      }
      topRight={
        <Box>
          <RadioGroup
            aria-labelledby='select-kp-object-type-label'
            sx={{ display: 'flex', flexFlow: 'row wrap' }}
            value={selectedOwnerCode ?? ''}
            onChange={(e, val) => handleOwnerCodeSwitch(val)}>
            <FormControlLabel
              value={ownerCode}
              control={<Radio disableRipple />}
              label={
                <Typography variant='body2' fontSize='14px' lineHeight='16px' fontWeight='400' letterSpacing='0.25px'>
                  {tComponents('DMFinderHeader.OnlyOurOwnerCode', { ownerCode })}
                </Typography>
              }
              labelPlacement='end'
            />
            {ownerCodes && (
              <FormControlLabel
                value={ownerCodes}
                control={<Radio disableRipple />}
                label={
                  <Typography variant='body2' fontSize='14px' lineHeight='16px' fontWeight='400' letterSpacing='0.25px'>
                    {tComponents('DMFinderHeader.OnlyOurOwnerCodes')} ({ownerCodes})
                  </Typography>
                }
                labelPlacement='end'
              />
            )}
            <FormControlLabel
              value=''
              control={<Radio disableRipple />}
              label={
                <Typography variant='body2' fontSize='14px' lineHeight='16px' fontWeight='400' letterSpacing='0.25px'>
                  {tCommon('all')}
                </Typography>
              }
              labelPlacement='end'
            />
          </RadioGroup>
        </Box>
      }
      bottomRight={
        <Container fullWidth right>
          <FormControlLabel
            control={<Switch color='secondary' checked={hasLocation} onChange={() => setHasLocation(!hasLocation)} />}
            label={tComponents('DMFinderHeader.HasLocation')}
          />
          <FormControlLabel
            control={<Switch color='secondary' checked={hasPictures} onChange={() => setHasPictures(!hasPictures)} />}
            label={tComponents('DMFinderHeader.HasPictures')}
          />
          <Container
            sx={{
              width: 'auto',
              minWidth: '150px',
            }}>
            <LicenseSelector license={mediaLicense} onChange={onMediaLicenseChanged} />
          </Container>
          {objectTypeSelectable && (
            <Container
              sx={{
                width: 'auto',
                minWidth: '150px',
              }}>
              <TextField
                variant='outlined'
                color='secondary'
                label={tComponents('DMFinderHeader.ObjectType')}
                value={selectedObjectType || ''}
                onChange={(e) => {
                  onObjectTypeChanged((e.target.value as DMObjectType) || null);
                }}
                InputProps={{
                  startAdornment: selectedObjectType && (
                    <InputAdornment position='start'>
                      <IconButton aria-label={tCommon('clear')} onClick={() => onObjectTypeChanged(null)}>
                        <MaterialSymbol name='clear' color='error' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  renderValue: (ot: unknown): ReactNode => tCommon(`DMContentType.${ot as DMObjectType}`),
                  MenuProps: {
                    sx: { maxHeight: '400px' },
                  },
                }}
                fullWidth
                select>
                {Object.values(DMObjectType).map((ot: DMObjectType) => {
                  const selected = ot === selectedObjectType;
                  return (
                    <MenuItem key={ot} value={ot} selected={selected} disabled={selected}>
                      <Container left fullWidth>
                        {selected ? (
                          <MaterialSymbol name='radio_button_checked' fill color='secondary' />
                        ) : (
                          <MaterialSymbol name='radio_button_unchecked' fill color='secondary' />
                        )}
                        {tCommon(`DMContentType.${ot}`)}
                      </Container>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Container>
          )}
        </Container>
      }
      onQueryChange={onQueryChanged}
    />
  );
};

export default DMFinderHeader;
