import React, { FC, useEffect, useState } from 'react';
import { Api } from '../../../../services/Api';
import { KPOwner, KPProcessedFields } from '../../../../declarations/models/KP';
import { KPOwnerItem } from '../../../../declarations/models/blocks/KPBlock';
import { resolveLocalizedString } from '../../../../utils/strings';
import { KPSchemaId } from '../../../../declarations/KPSchemaId';
import KPBlockPreviewItem from './KPBlockPreviewItem';

export const KPBlockOwnerPreviewItem: FC<{
  lng: string;
  item: KPOwnerItem;
  layout?: string;
  size?: string;
  displayDescription?: boolean;
}> = ({ lng, item, layout, size, displayDescription }) => {
  const [owner, setOwner] = useState<KPOwner>();
  const [presentations, setPresentations] = useState<Array<KPProcessedFields>>([]);

  useEffect(() => {
    if (item.id) {
      const ctx = Api.getKPOwner(item.id);
      const presCtx = Api.getKPObjects({ owner_id: item.id, schema_ids: [KPSchemaId.KP_PRESENTATION] });

      ctx
        .fetchDirect(null)
        .then((o) => !!o && setOwner(o))
        .finally(ctx.abort);
      presCtx
        .fetchDirect(null)
        .then((p) => !!p?.items && setPresentations(p.items))
        .finally(presCtx.abort);
    } else {
      setOwner(undefined);
      setPresentations([]);
    }
  }, [item.id]);

  return (
    <>
      {!item.listPresentations && (
        <KPBlockPreviewItem
          type='KPMuseum'
          size={size || 'L'}
          layout={layout || 'img'}
          key={owner?.id}
          lng={lng}
          displayDescription={displayDescription}
          item={
            {
              document_id: owner?.id,
              image_src: owner?.image_url,
              title: item?.altTitle || resolveLocalizedString(lng, owner?.name),
              description: item?.altDescription || resolveLocalizedString(lng, owner?.description),
            } as KPProcessedFields
          }
        />
      )}
      {item.listPresentations &&
        presentations.map((p) => (
          <KPBlockPreviewItem
            type='Presentation'
            size='M'
            key={p.document_id}
            lng={lng}
            item={p}
            displayDescription={displayDescription}
          />
        ))}
    </>
  );
};

export default KPBlockOwnerPreviewItem;
