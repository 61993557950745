import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import FinderButton, { FinderType } from './Finder/FinderButton';
import Container from './Container';
import { M24MediaModel } from '../declarations/models/M24MediaModel';
import Styles from '../assets/js/Styles';
import DeleteButton from './DeleteButton';
import { prepareMediaItemAsDMMediaModel, prepareMediaItemAsM24MediaModel } from '../utils/MediaUtils';
import { ChildrenProp } from '../declarations/ChildrenProp';
import { GenericMedia } from '../declarations/GenericMedia';
import { DMMediaModel } from '../declarations/models/DMMediaModel';
import { isM24MediaModel } from '../utils/typeChecks';
import { useMediaEditorContext } from '../editor/MediaEditor/MediaEditorContext';
import { Api } from '../services/Api';
import { useStore } from './store/Store';
import ImageWithFocusPointSelector from './ImageWithFocusPointSelector';
import { ExternalMediaModel } from '../declarations/models/ExternalMediaModel';
import { BlockSpecificPage } from '../editor/lib/declarations/BlockSpecificPage';
import { BlockPath, useCurrentBlock } from '../editor/PageEditor/CurrentBlockProvider';
import { BaseBlock } from '../declarations/models/blocks/BaseBlock';

interface AddMediaObjectProps extends ChildrenProp {
  mediaObject?: M24MediaModel | ExternalMediaModel | null;
  canEdit?: boolean;
  endChildren?: ReactNode;
  variant?: 'default' | 'banner';
  finderType?: FinderType.M24 | FinderType.DM;
}

/**
 *
 * @param mediaObject - get mediaObject from useWatch in parent since useController
 *                    does not update on external changes to the value
 * @param canEdit - If true you can edit the media from the module
 * @param children - overrides default render. Displayed when a media object is selected.
 */
export const AddMediaObject: FC<AddMediaObjectProps> = ({
  mediaObject,
  canEdit,
  children,
  endChildren,
  variant = 'default',
  finderType,
}) => {
  const { blockPath } = useCurrentBlock();
  const { t } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { state } = useStore();
  const siteId = state.selectedSite?.id;
  const { setMediaId, setEditorOpen, editorOpen } = useMediaEditorContext();

  const {
    field: { onChange: onChangeMediaObject },
  } = useController<
    BlockSpecificPage<BaseBlock & { mediaobject?: M24MediaModel | ExternalMediaModel }>,
    `${BlockPath}.mediaobject`
  >({
    name: `${blockPath}.mediaobject`,
  });

  const isM24Media = isM24MediaModel(mediaObject);
  const [m24Item, setM24Item] = useState<M24MediaModel | undefined>(isM24Media ? mediaObject : undefined);

  useEffect(() => {
    if (isM24Media) {
      if (!editorOpen && canEdit && siteId && mediaObject.id) {
        const ctx = Api.getM24MediaResource(siteId, mediaObject.id);
        ctx
          .fetchDirect(null)
          .then((e) => e && setM24Item(e))
          .finally(ctx.abort);
      }
    } else setM24Item(undefined);
  }, [mediaObject, siteId, editorOpen, isM24Media, canEdit]);

  const handleEditMedia = () => {
    if (isM24Media && mediaObject.id) {
      setMediaId(mediaObject.id);
      setEditorOpen(true);
    }
  };

  const resetContentSettings = () => {
    onChangeMediaObject({
      ...mediaObject,
      alpha_on: false,
      vignette_on: false,
      gradient_on: false,
    });
  };

  const handleFocusPointChanged = (focusPoint: { x: string; y: string }) => {
    onChangeMediaObject({
      ...mediaObject,
      local: {
        ...mediaObject?.local,
        x: focusPoint.x,
        y: focusPoint.y,
      },
    });
  };

  return (
    <Container
      fullWidth
      fullHeight
      column
      gap={2}
      sx={{
        ...(variant === 'default' && {
          backgroundColor: Styles.Colors.LIGHT_GREY,
          p: 2,
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
        }),
      }}>
      <Container fullWidth>
        {(!finderType || finderType === FinderType.M24) && (
          <FinderButton
            type={FinderType.M24}
            finderProps={{
              onSelectionConfirmed: (items: Array<GenericMedia<M24MediaModel>>) => {
                resetContentSettings();
                items.forEach((item) => onChangeMediaObject(prepareMediaItemAsM24MediaModel(item.source)));
              },
            }}
            multiSelect={false}
          />
        )}
        {(!finderType || finderType === FinderType.DM) && (
          <FinderButton
            type={FinderType.DM}
            finderProps={{
              onSelectionConfirmed: (items: Array<GenericMedia<DMMediaModel>>) => {
                resetContentSettings();
                items.forEach((item) => onChangeMediaObject(prepareMediaItemAsDMMediaModel(item.source, item.url)));
              },
            }}
            multiSelect={false}
          />
        )}
      </Container>
      {(!!m24Item?.url || !!mediaObject?.url) && (
        <Container
          p={2}
          column
          fullWidth
          sx={{
            ...(variant === 'banner'
              ? {
                  backgroundColor: Styles.Colors.DARK_GREY,
                  color: Styles.Colors.WHITE,
                }
              : {
                  backgroundColor: Styles.Colors.WHITE,
                  border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                }),
            borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          }}>
          <Container fullWidth spaceBetween top gap={2}>
            <ImageWithFocusPointSelector
              // key={m24Item?.id}
              src={`${m24Item?.url || mediaObject?.url || ''}`}
              mimetype={m24Item?.mimetype || mediaObject?.mimetype || ''}
              title={m24Item?.title}
              alt={m24Item?.content?.alttext || mediaObject?.local?.caption}
              maxWidth='150px'
              x={mediaObject?.local?.x || '0'}
              y={mediaObject?.local?.y || '0'}
              onFocusPointChanged={handleFocusPointChanged}
            />
            <Container column right spaceBetween sx={{ alignSelf: 'stretch', flexGrow: 1 }}>
              <DeleteButton
                onConfirm={() => onChangeMediaObject(null)}
                deleteLabel={tCommon('remove')}
                deleteIcon={<MaterialSymbol color='error' name='do_not_disturb_on' fill />}
              />
              {children}
              {isM24Media && canEdit && (
                <Container column left fullWidth>
                  {!m24Item?.content?.alttext && (
                    <Container>
                      <MaterialSymbol name='error' fill style={{ color: Styles.Colors.MEDIUM_RED }} />
                      <Typography variant='caption' color='error'>
                        {t('M24Content.MissingAltTextWarning')}
                      </Typography>
                    </Container>
                  )}
                  <Button
                    sx={{
                      typography: 'caption',
                      textAlign: 'left',
                      marginTop: 'auto',
                      '&:hover': { textDecoration: 'underline' },
                    }}
                    startIcon={<MaterialSymbol name='edit' fill />}
                    onClick={handleEditMedia}>
                    {t('M24Content.EditGlobalMediaData')}
                  </Button>
                </Container>
              )}
            </Container>
          </Container>
          {endChildren}
        </Container>
      )}
    </Container>
  );
};
