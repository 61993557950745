import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Settings } from '@/Settings';
import { Status } from '../../../../declarations/models/Status';
import { BlockPreviewComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { Api } from '../../../../services/Api';
import { useStore } from '../../../../components/store/Store';
import { Page } from '../../../../declarations/models/Page';
import { EventsAutoBlock } from '../../../../declarations/models/blocks/EventsBlocks';
import EventsBlockPreviewItem from './EventsBlockPreviewItem';

export const EventsAutoBlockPreview: BlockPreviewComponent = () => {
  const { t: tComp } = useTranslation('components');
  const { block } = useCurrentBlock();
  const { state } = useStore();
  const siteId = state.selectedSite?.id;
  const currentBlock = block as EventsAutoBlock;
  const langCode = state.selectedSiteLanguage || 'no';
  const rows = currentBlock.maxSearchResults || 20;
  const locationIds = currentBlock?.locationIds || '';
  const subCategoryIds = currentBlock?.subCategories || '';
  const periode = currentBlock?.showForPeriodType;
  const siteIds = currentBlock?.siteIds || '';
  const filter =
    currentBlock.userCategoryFilter ||
    currentBlock.userMuseumFilter ||
    currentBlock.userTargetGroupFilter ||
    currentBlock.userLocationFilter ||
    currentBlock.userTodayFilter ||
    currentBlock.userDateFilter;
  const [pages, setPages] = useState<Array<Page>>([]);
  let startAfter = 'today';
  let order = 'asc';
  let startBefore = currentBlock?.showForPeriodType;
  let startDate = '';
  let pastEvents = false;
  const disableExpandedDates = currentBlock?.disableExpandedDates || false;

  if (periode === 'previous') {
    order = 'desc';
    startAfter = '';
    startBefore = 'today';
    pastEvents = true;
  }
  if (periode === 'specify') {
    if (currentBlock?.showForPeriodStart) {
      startAfter = `${currentBlock?.showForPeriodStart?.substring(0, 4)}-${currentBlock?.showForPeriodStart?.substring(
        4,
        6,
      )}-${currentBlock?.showForPeriodStart?.substring(6, 8)}`;
    }
    if (currentBlock?.showForPeriodEnd) {
      startDate = `${currentBlock?.showForPeriodEnd?.substring(0, 4)}-${currentBlock?.showForPeriodEnd?.substring(
        4,
        6,
      )}-${currentBlock?.showForPeriodEnd?.substring(6, 8)}`;
    }
  }

  const preview = true;

  const basePortal = Settings.PORTAL_HOST || 'https://dev.museum24.no';
  const previewUrl = `${basePortal}/api/page/pages/list?is_event=true&locale=${langCode}&order_by=event_start&order=${order}&site_ids=${siteIds}&site_id=${siteId}&rows=${rows}&past_events=${pastEvents}`;
  const previewUrlMore = `&location_ids=${locationIds}&sub_categories=${subCategoryIds}&event_start_after=${startAfter}&event_start_before=${
    startDate !== '' ? startDate : startBefore
  }&disable_expanded_dates=${disableExpandedDates}`;

  const portalPreview = `${previewUrl}${previewUrlMore}`;

  const dummyPage: Page = {
    locale: langCode,
    site_id: siteId || 0,
    title: 'Preview arrangement',
    description: 'Se portal for reelt resultat',
    status: Status.PUBLISHED,
    type: 'page',
    image_src: 'https://dms-cf-05.dimu.org/image/013AjsvZ6qzc',
    location: {
      title: 'Sted',
    },
    event_data: {
      time_periods: [
        {
          start_date: '20991224-000000-000000',
          start_time: '19000101-180000-000000',
        },
      ],
    },
  };
  const dummyPages: Array<Page> = [
    {
      ...dummyPage,
    },
    {
      ...dummyPage,
    },
    {
      ...dummyPage,
    },
    {
      ...dummyPage,
    },
    {
      ...dummyPage,
    },
    {
      ...dummyPage,
    },
  ];

  const h = new Headers();

  if (basePortal.indexOf('dev') > 0 || basePortal.indexOf('exp') > 0) {
    const auth = btoa('dev:ved');
    h.set('Authorization', `Basic ${auth}`);
    h.set('Content-Type', 'application/json');
  }

  useEffect(() => {
    // clear pages
    setPages([]);
    if (siteId) {
      fetch(portalPreview, {
        method: 'GET',
        headers: h,
      })
        .then((res) => res.json())
        .then((data) => {
          setPages(data.pages || []);
        })
        .catch((error) => {
          setPages(dummyPages.slice(0, rows));
          // console.log(error)
        });
      /*
      const ctx = Api.getPagesListForSite(siteId, {
        is_event: true,
        sub_categories: currentBlock?.subCategories,
        rows: rows || 20,
        site_ids: currentBlock?.siteIds,
        status_list: [Status.PUBLISHED],
        locale: langCode,
      });
      ctx
        .fetchDirect(null)
        .then((res) => setPages(res || []))
        .finally(ctx.abort);
        */
    }
  }, [
    siteId,
    currentBlock?.subCategories,
    currentBlock?.locationIds,
    currentBlock?.siteIds,
    currentBlock?.showForPeriodType,
    currentBlock?.maxSearchResults,
    currentBlock?.disableExpandedDates,
  ]);

  return (
    <div className={`pagelist pagelist--${currentBlock?.view || 'grid'}`}>
      <div className='pagelist__intro'>
        {block?.visibletitle && block?.title && <h2>{block?.title}</h2>}
        {block?.body && <p>{block?.body}</p>}
      </div>
      {preview && (
        <div
          className={`row row--cards ${currentBlock?.layout || ''} row--events row--${
            currentBlock?.view || 'grid'
          } gridSize-${currentBlock?.gridSize || 'auto'} gridMobileBehaviour-${
            currentBlock?.gridMobileBehaviour || 'auto'
          }`}>
          {filter && (
            <div className='user-filter'>
              <div className='preview__blockmessage'>
                <strong>{tComp('EventsBlock.PreviewFilterInfo')}</strong>
              </div>
            </div>
          )}
          <div className='row__grid'>
            <div className='row__content'>
              {pages.map((page, index) => (
                <EventsBlockPreviewItem key={`${page.id}`} autoPage={page} block={currentBlock} />
              ))}
            </div>
            <div className='row__footer'>
              <div className='pagelist__intro'>
                {currentBlock?.showAllLinkPage?.id && (
                  <button type='button' className='button button--primary'>
                    <span className='button__label'>
                      {currentBlock?.showAllLinkPage?.local?.title || currentBlock?.showAllLinkPage?.title}
                    </span>
                    <i className='button__icon i-nav-right' />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventsAutoBlockPreview;
