import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/components/store/Store';
import Container from '@/components/Container';
import { Box, Typography } from '@mui/material';
import { Api } from '@/services/Api';
import { Owner } from '@/declarations/models/Owner';
import { ChildrenProp } from '../declarations/ChildrenProp';

export const OwnerSettingsView: FC<ChildrenProp> = () => {
  const { t: tComponents } = useTranslation('components');
  const {
    state: { selectedSite, selectedSiteData },
  } = useStore();
  // console.log('selectedSite', selectedSite);
  const [owner, setOwner] = useState<Owner | null>(null);

  useEffect(() => {
    const ownerId = selectedSite?.owner?.id;
    if (ownerId) {
      Api.getOwnerById(ownerId)
        .fetch()
        .then((data) => {
          // console.log(data[0]); // do something with the owner data
          setOwner(data[0]);
        });
    }
  }, [selectedSite]);

  return (
    <Container left fullWidth p={2} top mt={2} column>
      <Typography my={2} variant='h1' fontSize='xx-large'>
        {tComponents('MainMenu.settings.owner')}
      </Typography>

      <Container left wrap column>
        <Box>
          {owner?.parent?.name && <>{owner?.parent?.name} /</>} <strong>{owner?.name} </strong> <br />
          Eier-ID i Museum24: {owner?.id} <br />
          Museumssignatur: {owner?.identifier} <br />
          Ekultur Museum-ID: {owner?.ekultur_museum_id}
        </Box>
        <br />
        <Box>
          <strong>Egne nettsteder ({owner?.sites?.length})</strong>
          <p>
            Redaksjonelle rettigheter til disse forvaltes på <i>ID {owner?.ekultur_museum_id}</i> i Ekultur
          </p>
          <ul>
            {owner?.sites?.map((site) => (
              <li key={site.id} title={`Site-ID:${site.id}`}>
                {site.name}
              </li>
            ))}
          </ul>
        </Box>

        {owner?.children && owner?.children?.length > 0 && (
          <Box>
            <strong>
              Eiere organisert under {owner?.name} ({owner?.children?.length}) og mappet mot egne Ekultur ID-er.
            </strong>
            <ul>
              {owner?.children
                ?.sort((a, b) => (a?.name && b?.name ? a.name.localeCompare(b.name) : 0))
                .map((child) => (
                  <li key={child.id} title={`Owner-ID:${child.id}`}>
                    {child.name}
                  </li>
                ))}
            </ul>
          </Box>
        )}
      </Container>
    </Container>
  );
};

export default OwnerSettingsView;
