import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { BlockPreviewComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { Api } from '../../../../services/Api';
import { KPObject } from '../../../../declarations/models/KP';
import { KPBlockModel, KPViewType, PresDisplayType } from '../../../../declarations/models/blocks/KPBlock';
import KPBlockPreviewItem from './KPBlockPreviewItem';
import MapPlaceholder from '../../../../assets/images/mjosa_map.png';

export const KPBlockPreview: BlockPreviewComponent = () => {
  const currentLang = i18next.language;
  const { block } = useCurrentBlock();
  const kpBlock = block as KPBlockModel;

  const [presentation, setPresentation] = useState<KPObject>();

  const getArticlesToList = () => {
    const articles = kpBlock?.articles ?? [];
    if (kpBlock.presentation?.displayType === PresDisplayType.ALL_POINTS) {
      return [...(presentation?.references?.slice(0, 20).map((r) => ({ id: r.to_document_id })) ?? []), ...articles];
    }
    if (kpBlock.presentation?.displayType === PresDisplayType.PICK_POINTS) {
      return [...(kpBlock?.presentation?.articles ?? []), ...articles];
    }
    if (kpBlock.presentation?.displayType === PresDisplayType.SELECTION) {
      const selections = presentation?.content.selections;
      if (!selections?.length) return articles;
      const selIndex = kpBlock.presentation.selectionIndex ?? 0;
      return [
        ...(selections[selections.length > selIndex ? selIndex : 0].articles
          .slice(0, 20)
          .map((r) => ({ id: r.document_id })) ?? []),
        ...articles,
      ];
    }
    return articles;
  };

  useEffect(() => {
    if (kpBlock.presentation?.id) {
      const ctx = Api.getKPObjectFull(kpBlock.presentation.id);
      ctx
        .fetchDirect(null)
        .then((res) => !!res && setPresentation(res))
        .finally(ctx.abort);
    } else setPresentation(undefined);
  }, [kpBlock.presentation?.id]);

  return (
    <div
      className={`ekultur ekultur--kulturpunkt ekultur--${kpBlock.view} gridSize-${
        kpBlock?.gridSize || 'auto'
      } ekultur--${kpBlock?.width || 'auto'}  ${kpBlock?.whiteBackground && 'skin-kulturpunkt-white'}
      `}>
      <div className='ekultur__intro'>
        {kpBlock?.visibletitle && kpBlock?.title ? <h2>{kpBlock.title}</h2> : null}
        {kpBlock?.body && <p>{kpBlock?.body}</p>}
      </div>
      {kpBlock?.view === KPViewType.MAP ? (
        <div className='preview__map'>
          <div>
            Her vil det vises et kart med kulturpunkter.
            <br /> Se endelig resultat i portal.
          </div>
          <img style={{}} src={MapPlaceholder} alt='map' />
        </div>
      ) : (
        <>
          <div className='ekultur__grid'>
            {getArticlesToList().map((o) => (
              <KPBlockPreviewItem
                key={o.id}
                lng={currentLang}
                id={o.id}
                integrated={kpBlock?.integratedView}
                layout={kpBlock?.layout || 'auto'}
                displayDescription={kpBlock?.displayDescription}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default KPBlockPreview;
