import Styles from '@/assets/js/Styles';
import { useStore } from '@/components/store/Store';
import { useTranslation } from 'react-i18next';
import Container from '@/components/Container';
import { Alert, Button, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { Api } from '@/services/Api';
import React, { useState } from 'react';
import { MaterialSymbol } from '@/components/MaterialSymbol';

export const ReindexPagesWidget = () => {
  const {
    state: { selectedSite },
  } = useStore();
  const { t: tComponents } = useTranslation('components');
  const [loading, setLoading] = useState(false);
  const [indexAllSites, setIndexAllSites] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  if (!selectedSite) {
    return null;
  }

  const onClick = async () => {
    setLoading(true);
    setError('');
    setSuccess(false);

    const [, err] = await Api.reindexSiteOrPage(indexAllSites ? undefined : selectedSite.id).fetch();
    if (err) {
      console.error(err);
      setError(err.message ?? 'Unknown error');
      setSuccess(false);
    } else {
      setSuccess(true);
    }
    setLoading(false);
  };

  return (
    <Container
      column
      fullWidth
      sx={{
        p: 4,
        border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
      }}>
      <Container left fullWidth>
        <Typography fontSize='1.25rem' fontWeight={500}>
          {tComponents(`DashboardView.ReindexPagesWidget.Title`)}
        </Typography>
      </Container>
      <FormControlLabel
        control={<Checkbox checked={indexAllSites} onChange={(_e, checked) => setIndexAllSites(checked)} />}
        label='Indekser alle siter'
      />
      <Button
        onClick={onClick}
        variant='contained'
        disabled={loading}
        endIcon={loading ? <CircularProgress color='secondary' size={20} /> : null}>
        {tComponents(`DashboardView.ReindexPagesWidget.Reindex`)}
      </Button>
      {error && (
        <Alert severity='error' icon={<MaterialSymbol name='error' sx={{ height: '2rem', width: '2rem' }} />}>
          {tComponents('DashboardView.ReindexPagesWidget.ReindexFailed', { error })}
        </Alert>
      )}
      {success && (
        <Alert severity='success' icon={<MaterialSymbol name='check' sx={{ height: '2rem', width: '2rem' }} />}>
          {tComponents('DashboardView.ReindexPagesWidget.ReindexSuccess')}
        </Alert>
      )}
    </Container>
  );
};
