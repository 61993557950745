import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dialog, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { urlSafeString } from '@/utils/url';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page } from '@/declarations/models/Page';
import { Api } from '@/services/Api';
import { Status } from '@/declarations/models/Status';
import { SectionType } from '@/declarations/models/SectionType';
import { PageCreationSaveType } from '@/declarations/models/PageCreationSaveType';
import { SectionModelFactory } from '@/editor/lib/factories/SectionModelFactory';
import { ContentViewContentContext } from '@/views/ContentView/ContentViewContext';
import { MainCategory } from '@/declarations/Category';
import { getErrorMessage } from '@/utils/getErrorMessage';
import Container from './Container';
import { useStore } from './store/Store';

export type CreatePageParentPage = Pick<Page, 'id' | 'title'>;
export interface CreatePageModalProps {
  open: boolean;
  type?: 'page' | 'template';
  parentPage: CreatePageParentPage | null;
  pageFolderId?: number;
  saveTypes?: Array<PageCreationSaveType>;
  closeCreatePageModal: () => void;
  setPages: React.Dispatch<React.SetStateAction<Page[]>>;
  contentContext: ContentViewContentContext;
  /**
   * Callback that is called before the page is saved. Return false to prevent the page from being saved.
   * @param saveType
   */
  onBeforeSave?: (saveType: PageCreationSaveType) => Promise<boolean>;
}

export const CreatePageModal: FC<CreatePageModalProps> = ({
  open,
  type = 'page',
  parentPage = {} as CreatePageParentPage,
  pageFolderId,
  saveTypes = Object.values(PageCreationSaveType),
  closeCreatePageModal,
  setPages,
  contentContext,
  onBeforeSave,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;
  const selectedSiteLanguage = state.selectedSiteLanguage || 'no';
  const [title, setTitle] = useState('');
  const [path, setPath] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [hasTouchedPath, setHasTouchedPath] = useState<boolean>(false);
  const [existingPagePaths, setExistingPagePaths] = useState<string[]>([]);
  const [existingLocales, setExistingLocales] = useState<string[]>([]);

  const form = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();
  const isEvent = contentContext === ContentViewContentContext.EVENT;

  const validatePath = useMemo(() => {
    if (!path) return { isValid: true, message: '', isReservedForLanguage: false };

    const isUniqueInPaths =
      Array.isArray(existingPagePaths) &&
      !existingPagePaths
        .filter((existingPath) => existingPath != null)
        .some((existingPath) => existingPath.toLowerCase() === path.toLowerCase());

    const isUniqueInLocales =
      Array.isArray(existingLocales) &&
      !existingLocales.filter((locale) => locale != null).some((locale) => locale.toLowerCase() === path.toLowerCase());

    if (!isUniqueInPaths) {
      return {
        isValid: false,
        message: tComponents('CreatePageModal.PathAlreadyExists'),
        isReservedForLanguage: false,
      };
    }

    if (!isUniqueInLocales) {
      return {
        isValid: false,
        message: tComponents('CreatePageModal.PathReservedForLanguage'),
        isReservedForLanguage: true,
      };
    }

    return { isValid: true, message: '', isReservedForLanguage: false };
  }, [path, existingPagePaths, existingLocales, tCommon]);

  const reset = () => {
    setTitle('');
    setPath('');
    setHasTouchedPath(false);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      reset();

      (async () => {
        try {
          console.log('Fetching existing paths...');

          // Fetch existing paths
          const ctxPaths = Api.getSitePagePaths(selectedSiteId, selectedSiteLanguage);
          const [existingPaths, errorPaths] = await ctxPaths.fetch();

          if (errorPaths) {
            // Handle the error for existing paths
            enqueueSnackbar(tCommon('error'), {
              variant: 'expandableError',
              preventDuplicate: true,
              persist: true,
              expandedContent: getErrorMessage(errorPaths),
            });
            setExistingPagePaths([]);
          } else {
            // Handle the successful response for existing paths
            setExistingPagePaths(existingPaths || []);
          }

          // Fetch locales
          const ctxLocales = Api.getAllLocales();
          const [locales, errorLocales] = await ctxLocales.fetch();

          if (errorLocales) {
            // Handle the error for locales
            enqueueSnackbar(tCommon('error'), {
              variant: 'expandableError',
              preventDuplicate: true,
              persist: true,
              expandedContent: getErrorMessage(errorLocales),
            });
            setExistingLocales([]);
          } else {
            // Handle the successful response for locales
            setExistingLocales(locales || []);
          }
        } catch (error) {
          console.error('An unexpected error occurred:', error);
          enqueueSnackbar(tCommon('unexpectedError'), { variant: 'error' });
        }
      })();
    }
  }, [enqueueSnackbar, open, selectedSiteId, selectedSiteLanguage, tCommon]);

  const handleChangePath = (newPath: string): void => {
    setPath(urlSafeString(newPath, hasTouchedPath ? '/' : ''));
  };

  const handleSaveTemplate = async () => {
    const template: Page = {
      title,
      path,
      locale: selectedSiteLanguage,
      site_id: selectedSiteId,
      status: Status.DRAFT,
      type: 'page',
      content: {
        sections: [
          SectionModelFactory.makeSectionModel(SectionType.ARTICLE_HEAD),
          SectionModelFactory.makeSectionModel(SectionType.ARTICLE_BODY),
          SectionModelFactory.makeSectionModel(SectionType.ARTICLE_LINKS),
        ],
      },
    };

    const createdTemplate = await Api.postPageTemplate(template).fetchDirect(null);
    if (createdTemplate) {
      enqueueSnackbar(tComponents('CreatePageModal.CreateTemplateSuccessful'), { variant: 'success' });
      closeCreatePageModal();
      reset();
      setPages((previousPages) => [...previousPages, createdTemplate]);
    } else {
      enqueueSnackbar(tComponents('CreatePageModal.CreateTemplateFailed'), { variant: 'error' });
    }
  };

  const handleSavePage = async (saveType: PageCreationSaveType) => {
    if (selectedSiteId === 0) {
      enqueueSnackbar(tComponents('CreatePageModal.NoSiteId'), { variant: 'error' });
      return;
    }

    const formValid = form.current?.reportValidity?.() ?? form.current?.checkValidity?.() ?? true;
    if (!formValid) {
      enqueueSnackbar(tComponents('CreatePageModal.InvalidForm'), { variant: 'error' });
      return;
    }

    if (onBeforeSave) {
      const canSave = await onBeforeSave(saveType);
      if (!canSave) {
        return;
      }
    }

    const page: Page = {
      title,
      path,
      locale: selectedSiteLanguage,
      site_id: selectedSiteId,
      status: Status.DRAFT,
      type: 'page',
      content: {
        sections: [
          SectionModelFactory.makeSectionModel(SectionType.ARTICLE_HEAD),
          SectionModelFactory.makeSectionModel(SectionType.ARTICLE_BODY),
          SectionModelFactory.makeSectionModel(SectionType.ARTICLE_LINKS),
        ],
      },
    };

    if (isEvent && page.content) {
      page.main_category = MainCategory.EVENT;
      page.sub_categories = [];
      page.event_data = {
        time_periods: [],
      };
    }

    if (parentPage) {
      page.parent_id = parentPage.id;
    }

    if (pageFolderId) {
      page.page_folder_id = pageFolderId;
    }

    const createdPage = await Api.savePage(selectedSiteId, page).fetchDirect(null);
    if (createdPage) {
      if (saveType === 'save_and_close') {
        enqueueSnackbar(tComponents(`CreatePageModal.Create${isEvent ? 'Event' : 'Page'}Successful`), {
          variant: 'success',
        });
        closeCreatePageModal();
        reset();
        setPages((previousPages) => [...previousPages, createdPage]);
      } else if (saveType === 'save_and_edit') {
        enqueueSnackbar(tComponents(`CreatePageModal.Create${isEvent ? 'Event' : 'Page'}Successful`), {
          variant: 'success',
        });
        closeCreatePageModal();
        navigate(`/editor/${createdPage.id}`);
      } else if (saveType === 'save_and_create_new') {
        enqueueSnackbar(tComponents(`CreatePageModal.Create${isEvent ? 'Event' : 'Page'}Successful`), {
          variant: 'success',
        });
        reset();
        setPages((previousPages) => [...previousPages, createdPage]);
      }
    } else {
      enqueueSnackbar(tComponents(`CreatePageModal.Create${isEvent ? 'Event' : 'Page'}Failed`), {
        variant: 'error',
        persist: true,
      });
    }
  };

  function renderTitle() {
    if (isEvent) {
      return tComponents('CreatePageModal.CreateNewEvent');
    }
    if (type === 'template') {
      return tComponents('CreatePageModal.CreateNewTemplate');
    }
    return tComponents('CreatePageModal.CreateNewPage');
  }

  const isPathValid = validatePath.isValid;

  return (
    <Dialog open={open} onClose={closeCreatePageModal}>
      <Container p={2} column left top>
        <form ref={form}>
          <Typography variant='h4' component='h2' noWrap>
            {renderTitle()}
          </Typography>
          {parentPage && (
            <Typography color='textSecondary'>
              {/* TODO: Possibility to select/change parent */}
              {tComponents('CreatePageModal.Under')} {parentPage.title}
            </Typography>
          )}
          <Container column py={5} top left gap={2}>
            <TextField
              name='pageTitle'
              id='pageTitle'
              color='secondary'
              type='text'
              label={tComponents(`CreatePageModal.${isEvent ? 'Event' : 'Page'}Title`)}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value || '');
                if (!hasTouchedPath) {
                  handleChangePath(e.target.value);
                }
              }}
              required
              fullWidth
              autoComplete='off'
            />
            {type === 'page' && (
              <TextField
                name='pagePath'
                id='pagePath'
                color='secondary'
                type='text'
                label={tComponents('CreatePageModal.Path')}
                value={path}
                onChange={(e) => {
                  if (!hasTouchedPath) {
                    setHasTouchedPath(true);
                  }
                  handleChangePath(e.target.value || '');
                }}
                helperText={
                  !validatePath.isValid ? (
                    <>
                      {validatePath.message}
                      {validatePath.isReservedForLanguage && (
                        <>
                          <br />
                          <em>{tComponents('CreatePageModal.PathExample')}</em>
                        </>
                      )}
                    </>
                  ) : (
                    ''
                  )
                }
                error={!validatePath.isValid}
                fullWidth
                autoComplete='off'
                required
              />
            )}
          </Container>
          <Container right fullWidth>
            {type === 'page' ? (
              saveTypes.map((saveType) => (
                <Button
                  key={saveType}
                  disabled={loading || !isPathValid}
                  variant='contained'
                  color={saveType === PageCreationSaveType.SAVE_AND_EDIT ? 'success' : 'secondary'}
                  onClick={() => {
                    setLoading(true);
                    handleSavePage(saveType).then(() => setLoading(false));
                  }}>
                  {tComponents(`CreatePageModal.${saveType}`)}
                </Button>
              ))
            ) : (
              <Button
                disabled={loading}
                variant='contained'
                color='success'
                onClick={() => {
                  setLoading(true);
                  handleSaveTemplate().then(() => setLoading(false));
                }}>
                {tComponents('CreatePageModal.save_and_close')}
              </Button>
            )}
            <Button color='secondary' onClick={closeCreatePageModal} endIcon={<MaterialSymbol name='close' />}>
              {tCommon('cancel')}
            </Button>
          </Container>
        </form>
      </Container>
    </Dialog>
  );
};

export default CreatePageModal;
