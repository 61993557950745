import React, { FC } from 'react';
import { LinkExternal, M24MediaLinkItem } from '../../../declarations/models/LinkExternal';
import { Page } from '../../../declarations/models/Page';
import { LinkStyle } from '../../../declarations/models/LinkStyle';
import { apiTimestampToDate } from '../../../utils/dates';

export interface LinksPreviewProps {
  linkItems: Array<LinkExternal | M24MediaLinkItem | Page>;
  linkStyle?: LinkStyle;
  showDate?: boolean;
  overrideListClass?: string;
  skin?: string;
}

export const LinksPreview: FC<LinksPreviewProps> = ({
  linkItems,
  linkStyle,
  showDate = false,
  overrideListClass,
  skin,
}) => {
  const listClass = `${overrideListClass || 'article__links'} article__links--${linkStyle}`;
  return (
    <ul
      className={
        linkStyle === LinkStyle.TEXT_LINKS || linkStyle === LinkStyle.ARROW_LINKS ? listClass : 'module__buttons'
      }>
      {linkItems?.map((linkItem, index) => {
        const link = linkItem as Page & { local?: { title?: string }; skin?: string };
        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={`${linkStyle === LinkStyle.BUTTONS ? 'module__button' : ''} status--${link?.status} ${
                linkStyle === LinkStyle.ARROW_LINKS ? 'module--simplelink' : ''
              } ${linkStyle === LinkStyle.ARROW_LINKS ? skin || 'no-skin' : 'no-skin'} `}>
              {link?.local?.title || link?.title || link?.id}
            </a>
            {showDate && linkStyle === LinkStyle.TEXT_LINKS && link?.published_at && (
              <time className='meta__time'> ({apiTimestampToDate(link?.published_at)?.toLocaleDateString()})</time>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default LinksPreview;
